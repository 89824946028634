<template>
  <main class="main" id="top">
    <div class="container-fluid" data-layout="container-fluid">
      <Navigation />
      <div class="content">
        <Header />
    <div class="row justify-content-center">
      <div class="col-lg-12">
        <!--start content-->
        <div class="card mb-3">
          <div class="card-header">
            <h5 class="mb-0" data-anchor="data-anchor" id="card-styles">프로그램 후기</h5>
          </div>
          <div class="card-body bg-light border-top">
            <div class="row align-items-center">
              <div class="col d-flex align-items-center">
                <h5 class="card-title">{{ program.title }}</h5>
              </div>
              <div class="col-auto">
                <span class="badge rounded-pill d-block p-2" :class="$func.getProgramStatusColor(parseInt(program.status))">
                  {{$func.getProgramStatusLabel(parseInt(program.status))}}</span>
                <small>시작일 : {{$func.getShortDate(program.startDate)}}</small>
              </div>
            </div>
          </div>
          <div class="card-footer border-top ">
            <a class="btn btn-falcon-default btn-sm ms-2" href="/admin/programs"><span class="far fa-list-alt me-1"></span>프로그램 목록</a>
          </div>
        </div>
        <div class="card mb-3">
            <div class="card-header d-flex justify-content-between">
              <h5 class="mb-0">후기 목록</h5>
              <div class="mb-0">
                <select class="form-select form-select-sm" v-model="sort" @change="getProgramReviews">
                  <option value="1">최신순</option>
                  <option value="2">높은 평점</option>
                  <option value="3">낮은 평점</option>
                </select>
              </div>
            </div>
          <div class="card-body pt-0">
              <div class="table-responsive scrollbar">
                <table class="table table-hover table-striped table-bordered fs--1 mb-0">
                  <thead>
                    <tr>
                      <th class="text-center" style="width:120px">사용자</th>
                      <th class="text-center" style="width:60px">별점</th>
                      <th class="text-center">후기</th>
                      <th class="text-center" style="width:120px">등록일자</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr class="align-middle" v-for="review in reviewItems" :key="review.id">
                      <td class="text-center">{{review.userName}}</td>
                      <td class="text-center">{{review.rating}}</td>
                      <td>{{review.review}}</td>
                      <td class="text-center">{{$func.getShortDate(review.createTime)}}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
          </div>
        </div> 
      </div>
    </div>
        <FooterContent />
      </div>
    </div>
  </main>
</template>
<script>
import moment from 'moment';
import Header from '@/components/admin/common/Header.vue';
import Navigation from '@/components/admin/common/Navigation.vue';
import FooterContent from '@/components/admin/common/FooterContent.vue';
export default {
  name:'programReview',
  components: {
    moment,
    Header,
    Navigation,
    FooterContent,
  },
  data() {
    return {
      programId: -1,
      program: {},
      sort:1,
      reviewItems:[],
    };
  },
  created() {
    //console.log('created - programId : ' + this.$route.params.id);
    this.programId = this.$route.params.id;
    this.getProgramData();
  },
  methods: {
    getProgramData() {
      //console.log('getProgramData');
      this.$http
        .get('/admin-api/get-program.nolto',{
          params: {
            programId: this.programId,
          }
        })
        .then((response) => {
          //console.log(response);
          if (response.data.error) {
            this.$toast.error(response.data.message);
            return;
          }
          this.program = response.data.program;
         this.getProgramReviews();
        })
        .catch((error) => {
          console.log(error);
          
        });
    },
    getProgramReviews() {
      //console.log('getProgramReviews');
      this.$http
        .get('/admin-api/get-program-review.nolto',{
          params: {
            programId: this.programId,
            sort: this.sort
          }
        })
        .then((response) => {
          //console.log(response);
          if (response.data.error) {
            this.$toast.error(response.data.message);
            return;
          }
          this.reviewItems = response.data.reviewItems;
        })
        .catch((error) => {
          console.log(error);
          
        });
    },
  },
};
</script>
