<template>
  <main class="main" id="top">
    <div class="container-fluid" data-layout="container-fluid">
      <Navigation />
      <div class="content">
        <Header />
        <div class="row g-3 mb-3">
          <div class="col-md-6 col-xxl-3">
            <div class="card h-md-100 ecommerce-card-min-width">
              <div class="card-header pb-0">
                <h6 class="mb-0 mt-2 d-flex align-items-center">Weekly Sales<span class="ms-1 text-400" data-bs-toggle="tooltip" data-bs-placement="top" title="Calculated according to last week's sales"><span class="far fa-question-circle" data-fa-transform="shrink-1"></span></span></h6>
              </div>
              <div class="card-body d-flex flex-column justify-content-end">
                <div class="row">
                  <div class="col">
                    <p class="font-sans-serif lh-1 mb-1 fs-4">$47K</p><span class="badge badge-soft-success rounded-pill fs--2">+3.5%</span>
                  </div>
                  <div class="col-auto ps-0">
                    <div class="echart-bar-weekly-sales h-100"></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-6 col-xxl-3">
            <div class="card h-md-100">
              <div class="card-header pb-0">
                <h6 class="mb-0 mt-2">Total Order</h6>
              </div>
              <div class="card-body d-flex flex-column justify-content-end">
                <div class="row justify-content-between">
                  <div class="col-auto align-self-end">
                    <div class="fs-4 fw-normal font-sans-serif text-700 lh-1 mb-1">58.4K</div><span class="badge rounded-pill fs--2 bg-200 text-primary"><span class="fas fa-caret-up me-1"></span>13.6%</span>
                  </div>
                  <div class="col-auto ps-0 mt-n4">
                    <div class="echart-default-total-order" data-echarts='{"tooltip":{"trigger":"axis","formatter":"{b0} : {c0}"},"xAxis":{"data":["Week 4","Week 5","week 6","week 7"]},"series":[{"type":"line","data":[20,40,100,120],"smooth":true,"lineStyle":{"width":3}}],"grid":{"bottom":"2%","top":"2%","right":"10px","left":"10px"}}' data-echart-responsive="true"></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-6 col-xxl-3">
            <div class="card h-md-100">
              <div class="card-body">
                <div class="row h-100 justify-content-between g-0">
                  <div class="col-5 col-sm-6 col-xxl pe-2">
                    <h6 class="mt-1">Market Share</h6>
                    <div class="fs--2 mt-3">
                      <div class="d-flex flex-between-center mb-1">
                        <div class="d-flex align-items-center"><span class="dot bg-primary"></span><span class="fw-semi-bold">samsung</span></div>
                        <div class="d-xxl-none">33%</div>
                      </div>
                      <div class="d-flex flex-between-center mb-1">
                        <div class="d-flex align-items-center"><span class="dot bg-info"></span><span class="fw-semi-bold">Huawei</span></div>
                        <div class="d-xxl-none">29%</div>
                      </div>
                      <div class="d-flex flex-between-center mb-1">
                        <div class="d-flex align-items-center"><span class="dot bg-300"></span><span class="fw-semi-bold">Huawei</span></div>
                        <div class="d-xxl-none">20%</div>
                      </div>
                    </div>
                  </div>
                  <div class="col-auto position-relative">
                    <div class="echart-market-share"></div>
                    <div class="position-absolute top-50 start-50 translate-middle text-dark fs-2">26M</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-md-6 col-xxl-3">
            <div class="card h-md-100">
              <div class="card-header d-flex flex-between-center pb-0">
                <h6 class="mb-0">Weather</h6>
                <div class="dropdown font-sans-serif btn-reveal-trigger">
                  <button class="btn btn-link text-600 btn-sm dropdown-toggle dropdown-caret-none btn-reveal" type="button" id="dropdown-weather-update" data-bs-toggle="dropdown" data-boundary="viewport" aria-haspopup="true" aria-expanded="false"><span class="fas fa-ellipsis-h fs--2"></span></button>
                  <div class="dropdown-menu dropdown-menu-end border py-2" aria-labelledby="dropdown-weather-update"><a class="dropdown-item" href="#!">View</a><a class="dropdown-item" href="#!">Export</a>
                    <div class="dropdown-divider"></div><a class="dropdown-item text-danger" href="#!">Remove</a>
                  </div>
                </div>
              </div>
              <div class="card-body pt-2">
                <div class="row g-0 h-100 align-items-center">
                  <div class="col">
                    <div class="d-flex align-items-center"><img class="me-3" src="/img/icons/weather-icon.png" alt="" height="60" />
                      <div>
                        <h6 class="mb-2">New York City</h6>
                        <div class="fs--2 fw-semi-bold">
                          <div class="text-warning">Sunny</div>Precipitation: 50%
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-auto text-center ps-2">
                    <div class="fs-4 fw-normal font-sans-serif text-primary mb-1 lh-1">31&deg;</div>
                    <div class="fs--1 text-800">32&deg; / 25&deg;</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <FooterContent />
      </div>
    </div>
  </main>
</template>

<script>
// @ is an alias to /src
import Header from '@/components/admin/common/Header.vue';
import Navigation from '@/components/admin/common/Navigation.vue';
import FooterContent from '@/components/admin/common/FooterContent.vue';
export default {
  name:'admin-home',
  components: {
    Header,
    Navigation,
    FooterContent,
  },
};
</script>
