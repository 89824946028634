<template>
</template>
<script>
export default {
  name: "Logout",
  created() {
    //console.log('logout');
    localStorage.removeItem('userData');
    this.$func.setCookie('accessToken',null,0);
    this.$toast.success('로그아웃 되었습니다.');
    this.$router.push("/");
  },
};
</script>