<template>
  <main class="main" id="top">
    <div class="container" data-layout="container">
      <Navigation />
      <nav class="mt-3 mb-2" aria-label="breadcrumb">
        <ol class="breadcrumb">
          <li class="breadcrumb-item">
            <a href="/">Home</a>
          </li>
          <li class="breadcrumb-item active" aria-current="page">찜목록</li>
        </ol>
      </nav>
      <div class="content">
        <div class="row mt-3">
          <div class="col-md-6 col-xl-4 pb-4" v-for="program in bookmarkList" :key="program.id" >
            <ProgramGridItem :program="program" />
          </div>
          <div class="text-center" v-if="!bookmarkList || bookmarkList.length == 0">
            <div class="mb-3 mt-6">
              <img class="img-fluid" src="/img/no-data.svg" alt="찜한 오감체험이 없습니다." style="width: 10rem" />
            </div>
            <div class="mb-4">
              <p class="fs-1 mb-0">찜한 오감체험이 없습니다.</p>
            </div>
          </div>
        </div>
      </div>
      <FooterContent />
    </div>
  </main>
</template>
<script>
// @ is an alias to /src
import Navigation from '@/components/common/Navigation.vue';
import FooterContent from '@/components/common/FooterContent.vue';
import ProgramGridItem from '@/components/program/ProgramGridItem.vue';
export default {
  name: 'bookmarkList',
  components: {
    Navigation,
    FooterContent,
    ProgramGridItem,
  },
  data() {
    return {
      user: {},
      bookmarkList: [],
    };
  },
  created() {
    //console.log('onCreated');
    this.user = this.$func.getUser();
    this.getBookmarkList();
  },
  methods: {
    getBookmarkList() {
      this.$http
        .get('/favorite', {
          headers: {
            'Cache-Control': 'no-cache, no-store, must-revalidate',
            Pragma: 'no-store',
            Expires: '0',
          },
          params: {
            itemType: 3,
            skip: 0,
            limit: 1000,
          },
        })
        .then((response) => {
          // console.log('response:', response);
          if (response.data.error) {
            this.$toast.error(response.data.message);
            return;
          }
          response.data.forEach((v) => {
            this.bookmarkList.push(v.item)
          });

        })
        .catch((error) => {
          console.log(error);

        });
    },
  },
};
</script>
