<template>
  <div v-if="reservation.id !== undefined" class="d-flex border-bottom p-1 m-container" style="position: relative; width: 100%;">
    <span class="reservation-cancel reservation-cancel" :class=" `${(reservation.status === 3 || reservation.status === 4)  ? 'd-none' : ''}`"
          type="button" @click="cancelBtn()">
                취소하기
              </span>

    <a class="top-content-box avatar " style="width: 10%; min-width: 100px" >
      <div class="m-reservation-content-box">
      <span :class="`${$func.getReservationStatusColor(parseInt(reservation.status))}`"
            style="font-size: 14px; display: flex; height: 30px; align-items: center; justify-content: center; border-radius: 50px; width: 100px;">
                {{ $func.getReservationStatusLabel(parseInt(reservation.status)) }}
              </span>
      </div>

      <img class="rounded-soft" style="object-fit: contain; border-radius: 10px;" :src="imageUrl" :alt="reservation.programTitle" />
    </a>

    <div class="flex-1 ms-3" style="width: 90%">
      <div class="row align-items-between" style="width: 100%">
        <div class="col-lg-10">
          <div class="d-flex flex-column" >
            <div class="reservation-content-box" style="width: 100%; display: flex; gap: 10px;  margin-bottom: 10px ">
              <span :class="`${$func.getReservationStatusColor(parseInt(reservation.status))}`"
                    style="display: flex; height: 35px; align-items: center; justify-content: center; border-radius: 50px; width: 100px;">
                {{ $func.getReservationStatusLabel(parseInt(reservation.status)) }}
              </span>
            </div>

<!--            <h5 class="mb-2"><a :href="`/reservation/${reservation.RESERVATION_NO}`">{{ reservation.title }}</a></h5>-->
            <h5 class="mb-2 m-reservation-title" style="font-weight: bold">{{ reservation.programTitle }}</h5>

            <div class="reservation-content-box">
              <div class="d-flex align-items-center align-self-start gap-2 " style="width: 100%">
                <div style="width: 50%">
                  <div class="fs--3 mb-1" style=" font-weight: bold">일시</div>
                  <div class="fs--1 mb-1">{{ reservation.programTime }}</div>
                </div>
                <div style="width: 50%">
                  <div class="fs--3 mb-1" style="font-weight: bold">예약일</div>
                  <div class="fs--1 mb-1">{{ reservationTime }}</div>
                </div>
              </div>
            </div>
            <div class="reservation-content-box">
              <div class="d-flex align-items-center align-self-start gap-2 " style="width: 100%">
                <div style="min-width: 50%">
                  <div class="fs--3 mb-1" style="font-weight: bold">인원</div>
                  <div class="fs--1 mb-1">{{ reservation.quantity + "명" }}</div>
                </div>
                <div style="min-width: 50%">
                  <div class="fs--3 mb-1" style="font-weight: bold">결제금액</div>
                  <div v-if="reservation.payout !== undefined" class="fs--1 mb-1">{{reservation.payout.toLocaleString('ko-KR') }}원</div>
                </div>
              </div>
            </div>

            <div class="m-reservation-content-box" style="min-height: 250px;">
              <div style="width: 100%">
                <div class="fs--3 mb-1 m-reservation-sub" style=" font-weight: bold">일시</div>
                <div class="fs--1 mb-1 m-reservation-sub">{{ reservation.programTime }}</div>
              </div>
              <div style="min-width: 100%">
                <div class="fs--3 mb-1 m-reservation-sub" style="font-weight: bold">인원</div>
                <div class="fs--1 mb-1 m-reservation-sub">{{ reservation.quantity + "명" }}</div>
              </div>
              <div style="width: 100%">
                <div class="fs--3 mb-1 m-reservation-sub" style="font-weight: bold">예약일</div>
                <div class="fs--1 mb-1 m-reservation-sub">{{ reservationTime }}</div>
              </div>
              <div style="min-width: 100%">
                <div class="fs--3 mb-1 m-reservation-sub" style="font-weight: bold">결제금액</div>
                <div v-if="reservation.payout !== undefined" class="fs--1 mb-1 m-reservation-sub">{{reservation.payout.toLocaleString('ko-KR') }}원</div>
                    <span class="reservation-cancel" :class=" `${(reservation.status === 3 || reservation.status === 4)  ? 'd-none' : ''}`"
                          type="button" @click="cancelBtn()">
                                취소하기
                              </span>
              </div>
            </div>



          </div>

        </div>
      </div>
    </div>
  </div>
</template>
<script>
import moment from 'moment';
export default {
  name: 'Cart',
  components: {},
  data() {
    return {};
  },
  props: {
    reservation: {
      id: 0,
      programTitle: '오감체험 제목',
      programId: 0,
      programTime: '10월 26일(목) 18:00 ~ 19:00',
      imagePath: '/v2/resources/images/a9f1989e-9111-47ea-9f62-1a70b18e767e.jpg',
      status: 0,
      quantity: 1,
      payout: 0,
      reservationTime: '2024-06-11T09:58:37.522Z',
    },
  },
  created() {
    console.debug('created - reservation:', this.reservation);
  },
  computed: {
    imageUrl() {
      return process.env.VUE_APP_BASE_URL + this.reservation.imagePath;
    },
    reservationTime() {
      return moment(this.reservation.reservationTime.toLocaleString()).format('YYYY-MM-DD HH:mm');
    },
  },
  methods: {
    cancelBtn(){
      this.$emit('CancelEmit', this.reservation);
    }
  }
};
</script>
