import Vue from 'vue'
// axios
import axios from 'axios'
import router from '@/router';

const axiosIns = axios.create({
    // You can add your headers here
    // ================================
    baseURL: process.env.VUE_APP_API_URL,
    // timeout: 1000,
    // headers: {'X-Custom-Header': 'foobar'}
    // headers: {
    //   'Content-type': 'application/json',
    // },
})

// Request Interceptor
// axiosIns.interceptors.request.use(
//   config => {
//     // Get token from localStorage
//     const accessToken = localStorage.getItem('accessToken');
//     // If token is present add it to request's Authorization Header
//     if (accessToken) {
//       // eslint-disable-next-line no-param-reassign
//       config.headers.Authorization = `Bearer ${accessToken}`
//     }
//     return config
//   },
//   error => Promise.reject(error),
// )
axiosIns.interceptors.request.use(
    (config) => {
        let accessToken = document.cookie.match('(^|;) ?' + 'accessToken' + '=([^;]*)(;|$)');
        accessToken = accessToken ? accessToken[2] : null;

        if (accessToken) {
            config.headers = {
                ...config.headers,
                Authorization: `Bearer ${accessToken}`,
            };
        }

        return config;
    },
    (error) => {
        Promise.reject(error);
    }
);
axiosIns.interceptors.response.use(function (response) {
    return response
}, function (error) {
    if (error.response.status === 401) {
        console.log("error.response", error.response);
        router.push('/logout');
    }
    return Promise.reject(error.response.data)
})

export default axiosIns
