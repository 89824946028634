<template>
  <nav class="navbar navbar-light navbar-glass navbar-top navbar-expand">
    <button
      class="btn navbar-toggler-humburger-icon navbar-toggler me-1 me-sm-3"
      type="button"
      data-bs-toggle="collapse"
      data-bs-target="#navbarVerticalCollapse"
      aria-controls="navbarVerticalCollapse"
      aria-expanded="false"
      aria-label="Toggle Navigation"
    >
      <span class="navbar-toggle-icon"><span class="toggle-line"></span></span>
    </button>
    <a class="navbar-brand me-1 me-sm-3" href="/">
      <div class="d-flex align-items-center"><img class="me-2" src="/img/logo.svg" alt="" width="48" /></div>
    </a>
    <ul class="navbar-nav navbar-nav-icons ms-auto flex-row align-items-center">
      <li class="nav-item dropdown">
        <a
          class="nav-link notification-indicator-primary fa-icon-wait px-0"
          :class="hasUncheckedMemo ? 'notification-indicator' : ''"
          id="navbarDropdownNotification"
          href="#"
          role="button"
          data-bs-toggle="dropdown"
          aria-haspopup="true"
          aria-expanded="false"
          ><span class="fas fa-bell" data-fa-transform="shrink-6" style="font-size: 33px"></span
        ></a>
        <div class="dropdown-menu dropdown-menu-end dropdown-menu-card dropdown-menu-notification" aria-labelledby="navbarDropdownNotification">
          <div class="card card-notification shadow-none">
            <div class="card-header">
              <h6 class="card-header-title mb-0">메모알림</h6>
            </div>
            <div class="scrollbar-overlay" style="max-height: 19rem">
              <div class="list-group list-group-flush fw-normal fs--1">
                <div class="mb-0" v-if="hasUncheckedMemo">
                  <div class="list-group-item" v-for="uncheckedMemoCount in uncheckedMemoCountList" :key="uncheckedMemoCount.programId">
                    <a class="notification notification-flush notification-unread" :href="'/admin/program-reservation/' + uncheckedMemoCount.programId">
                      <div class="notification-body">
                        {{ uncheckedMemoCount.title + ' (' + uncheckedMemoCount.memoCount + '건)' }}
                      </div>
                    </a>
                  </div>
                </div>
                <div class="mb-0" v-else>
                  <div class="list-group-item">
                    <div class="notification-body notification notification-flush">새로운 메모가 없습니다.</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </li>
      <li class="nav-item dropdown" v-if="$func.isLoggedIn()">
        <a class="nav-link pe-0" id="navbarDropdownUser" href="#" role="button" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
          <div class="avatar avatar-xl">
            <img class="rounded-circle" :src="'/api/resource/profile.nolto?userUid=' + user.uid" alt="" />
          </div>
        </a>
        <div class="dropdown-menu dropdown-menu-end py-0" aria-labelledby="navbarDropdownUser">
          <div class="bg-white dark__bg-1000 rounded-2 py-2">
            <a class="dropdown-item" role="button" tabindex="0" @click="logout()"> <span class="fas fa-power-off me-2"></span><span>로그아웃</span> </a>
          </div>
        </div>
      </li>
      <li class="nav-item" v-else>
        <button class="btn btn-danger rounded-pill" @click="$router.push('/login')" type="button"><span class="fas fa-user me-1" data-fa-transform="shrink-3"></span><span>로그인</span></button>
      </li>
    </ul>
  </nav>
</template>
<script>
export default {
  name: 'navigation',
  data() {
    return {
      user: {},
      uncheckedMemoCountList: [],
    };
  },
  created() {
    console.debug('path:' + this.$route.path);
    if (!this.$func.isLoggedIn()) {
      this.$router.push("/logout");
    }
    this.user = this.$func.getUser();
    this.getUncheckedMemoCountList();
  },
    computed: {
    hasUncheckedMemo() {
      return this.uncheckedMemoCountList && this.uncheckedMemoCountList.length > 0;
    },
  },
  methods: {
    logout() {
      if (process.env.NODE_ENV == 'remote') {
        this.$router.push('/logout');
      } else {
        window.location.href = '/logout.nolto';
      }
    },
    getUncheckedMemoCountList() {
      //console.log('getUncheckedMemoCountList');
      this.$http
        .get('/admin-api/get-unchecked-product-memo-list.nolto')
        .then((response) => {
          //console.log(response);
          if (response.data.error) {
            this.$toast.error(response.data.message);
            return;
          }
          this.uncheckedMemoCountList = response.data.uncheckedProductMemoList;
          //console.log('uncheckedMemoCountList', this.uncheckedMemoCountList);
        })
        .catch((error) => {
          console.log(error);
          
        });
    },
  },
};
</script>
