<template>
  <div class="input-group input-group-sm"><span class="input-group-text border-0">옵션명</span>
    <input class="form-control border-0" type="text"
     ref="myLable" 
     v-model="myLable" 
     placeholder="옵션명을 입력하세요."
     @change="onChangeOption"/>
  </div>
</template>
<script>
export default {
  name: 'OptionEdit',
  props: {
    optionNo: {
      type: Number,
      default: 1,
    },
    label: {
      type: String,
      default: '',
    }
  },
  data() {
    return {
      myLable:'',
    }
  },
  created() {
    //console.log('created - optionNo:', this.optionNo,',label:',this.label);
    this.myLable = this.label;
  },
  //비동기 처리를 위해 watch를 사용한다.
  watch: {
    label: function(val) {
      //console.log('watch label:',val);
      this.myLable = val;
    },
  },
  methods: {
    onChangeOption() {
      //console.log('onChangeOption', this.optionNo)
      if (this.myLable == '' && this.optionNo ==1) {
        this.$toast.warning('기본옵션은 공백일 수 없습니다.');
        this.myLable = this.label;
        return;
      }
      this.$emit('onChangeOption', this.optionNo, this.myLable)
    },
    setFocus() {
      //console.log('setFocus')
      this.$refs.myLable.focus();
    }
  },
}
</script>
