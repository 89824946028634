<template>
  <div class="input-group input-group-sm"><span class="input-group-text border-0">자녀 {{child.index + 1}}</span>
    <datepicker
      input-class="form-control border-0 bg-white text-center yearpick"
      v-model="myValue"
      :language="ko"
      :format="yearPickFormat"
      id="birthYear" 
      minimum-view="year"
      width="150"
      @closed="onChangeChild"
    ></datepicker>
    <button class="btn p-0 ms-2" type="button" 
     title="삭제" 
     @click="onDeleteChild">
      <span class="text-500 fas fa-trash-alt"></span>
    </button>
  </div>
</template>
<script>
import { ko } from 'vuejs-datepicker/dist/locale';
import moment from 'moment';
import Datepicker from 'vuejs-datepicker';
export default {
  name: 'ChildEdit',
    components: {
    moment,
    Datepicker,
  },
  props: {
    child: {
      type: Object,
      required: true,
      default: {index:-1, value:'2010'},
    },
  },
  data() {
    return {
      myValue:'',
      yearPickFormat: 'yyyy',
      ko: ko,
    }
  },
  created() {
    //console.log('created - child:', this.child);
    this.myValue = this.child.value;
  },
  //참조하는 값이 변경될 경우 호출됨
  watch: {
    child: function(newChild) {
      //console.log('watch newChild:',newChild);
      this.myValue = newChild.value;
    },
  },
  methods: {
    onDeleteChild() {
      //console.log('onDeleteChild:', this.child)
      this.$emit('deleteChild', this.child)
    },

    onChangeChild() {
      //console.log('onChangeChild:', this.myValue, this.child.value);
      const changedChild = this.child;
      changedChild.value = moment(this.myValue).format('YYYY');  
      this.$emit('changeChild', changedChild)
    },
  },
}
</script>
