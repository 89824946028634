<template>
  <main class="main" id="top">
    <div class="container" data-layout="container">
      <Navigation />
      <nav class="mt-3 mb-2" aria-label="breadcrumb">
        <ol class="breadcrumb">
          <li class="breadcrumb-item">
            <a href="/">Home</a>
          </li>
          <li class="breadcrumb-item active" aria-current="page">오감체험</li>
        </ol>
      </nav>
      <div class="content">
        <div class="row flex-between-center mt-3 mb-3 mb-lg-2">
          <div class="col-sm-auto mb-2">
            <form class="d-flex search-box" v-on:submit.prevent>
              <input class="form-control search-input" type="search" v-model="search" placeholder="검색" @keyup.enter="getFirstPage" />
              <span class="fas fa-search search-box-icon" style="cursor: pointer" @click="getFirstPage"/>
            </form>
          </div>
<!--          <div class="col-sm-auto">-->
<!--            <div class="row gx-2 align-items-center justify-content-end">-->
<!--              <div class="col-auto">-->
<!--                <form class="row gx-2 align-items-center">-->
<!--                  <div class="col-auto"><small>카테고리</small></div>-->
<!--                  <div class="col-auto">-->
<!--                    <select class="form-select form-select-sm" v-model="categoryId" @change="getFirstPage">-->
<!--                      <option value="0">전체</option>-->
<!--                      <option value="1">역사</option>-->
<!--                      <option value="2">교과연계</option>-->
<!--                      <option value="3">캠프</option>-->
<!--                      <option value="4">진로체험</option>-->
<!--                      <option value="5">문화예술</option>-->
<!--                      <option value="6">자연힐링</option>-->
<!--                      <option value="7">취미</option>-->
<!--                      <option value="8">우리동네</option>-->
<!--                    </select>-->
<!--                  </div>-->
<!--                </form>-->
<!--              </div>-->
<!--              <div class="col-auto pe-0">-->
<!--                <form class="row gx-2 align-items-center">-->
<!--                  <div class="col-auto"><small>정렬</small></div>-->
<!--                  <div class="col-auto">-->
<!--                    <select class="form-select form-select-sm" v-model="sort" @change="getFirstPage">-->
<!--                      <option value="1">추천순</option>-->
<!--                      <option value="2">가격순</option>-->
<!--                      <option value="3">거리순</option>-->
<!--                      <option value="4">시간순</option>-->
<!--                    </select>-->
<!--                  </div>-->
<!--                </form>-->
<!--              </div>-->
<!--            </div>-->
<!--          </div>-->
        </div>
        <div class="row mb-2">
          <div class="col-md-6 col-xl-4 pb-4" v-for="program in programs" :key="program.id">
            <ProgramGridItem :program="program" />
          </div>
          <div class="text-center" v-show="isEmpty">
            <div class="mb-3 mt-6">
              <img class="img-fluid" src="/img/no-data.svg" alt="검색된 체험이 없습니다." style="width: 10rem" />
            </div>
            <div class="mb-4">
              <p class="fs-1 mb-0">검색된 체험이 없습니다.</p>
            </div>
          </div>
        </div>
      </div>
      <infinite-loading @infinite="infiniteHandler" ref="infiniteHandler" spinner="waveDots">
        <span slot="no-results">
          <span class="your-customize-class"></span>
        </span>
      </infinite-loading>
      <FooterContent />
    </div>
  </main>
</template>
<script>
// @ is an alias to /src
import Navigation from '@/components/common/Navigation.vue';
import FooterContent from '@/components/common/FooterContent.vue';
import ProgramGridItem from '@/components/program/ProgramGridItem.vue';
import InfiniteLoading from 'vue-infinite-loading';
export default {
  name: 'ProgramList',
  components: {
    Navigation,
    FooterContent,
    ProgramGridItem,
    InfiniteLoading,
  },
  data() {
    return {
      page: 1,
      search: '',
      total: 0,
      sort: '1',
      categoryId: '0',
      programs: [],
      isEmpty: false,
      hasMore: true,
    };
  },
  created() {
    this.getFirstPage();
  },
  computed: {

  },
  watch: {

  },
  methods: {
    getFirstPage() {
      if (this.$refs.infiniteHandler) {
        this.$refs.infiniteHandler.stateChanger.reset();
      }
      this.isEmpty = false;
      // todo 오감체험 리스트
      this.$http
          .get('/experience/web', {
            params: {
              limit: 15,
              skip: 0,
              search: this.search ?? "",
            },
          })
          .then((response) => {
            // console.log('response:', response.data.length);
            // console.log('response:', response.data);
            if (response.data.error) {
              this.$toast.error(response.data.message);
              return;
            }

            this.programs = response.data;
            if (this.programs.length === 0) this.isEmpty = true;
          })
          .catch((error) => {
            console.log(error);

          })
    },
    infiniteHandler($state) {
      this.isEmpty = false;
      if (this.hasMore) {
        // todo 오감체험 리스트
        this.$http
        .get('/experience/web', {
          params: {
            limit: 15,
            skip: this.programs.length,
            search: this.search,
          },
        })
        .then((response) => {
          // console.log('response:', response.data.length);
          // console.log('response:', response.data);
          if (response.data.error) {
            this.$toast.error(response.data.message);
            return;
          }
          setTimeout(() => {
            this.programs = [...this.programs, ...response.data];
            if(response.data.length < 15) {
              this.hasMore = false
            }
            $state.loaded();
          },1000)

        })
        .catch((error) => {
          console.log(error);
          $state.complete();
        });
      } else {
        $state.complete();
      }

    },
  },
};
</script>
