<template>
  <div id="app">
    <router-view />
  </div>
</template>
<script>
export default {
  name: 'app',
  metaInfo() {
    return {
      link: [
        { rel: 'preconnect', href: 'https://fonts.gstatic.com' },
        { rel: 'stylesheet', href: 'https://fonts.googleapis.com/css2?family=Noto+Sans+KR:wght@400;500;700&display=swap' },
        { rel: 'stylesheet', href: 'vendors/overlayscrollbars/OverlayScrollbars.min.css' },
        // {rel:"stylesheet",href:"vendors/choices/choices.min.css"},
        // {rel:"stylesheet",href:"vendors/flatpickr/flatpickr.min.css"},
        // {id:"style-default",rel:"stylesheet",href:"css/theme.css"},
        // {id:"user-style-default",rel:"stylesheet",href:"css/user.css"},
      ],
      script: [
        { src: 'js/config.js', async: true, defer: true },
        { src: 'vendors/tinymce/tinymce.min.js', async: true, defer: true },
        { src: 'vendors/overlayscrollbars/OverlayScrollbars.min.js', async: true, defer: true },
        { src: 'vendors/popper/popper.min.js', async: true, defer: true },
        { src: 'vendors/fontawesome/all.min.js', async: true, defer: true },
        { src: 'vendors/anchorjs/anchor.min.js', async: true, defer: true },
        { src: 'vendors/is/is.min.js', async: true, defer: true },
        { src: 'vendors/lodash/lodash.min.js', async: true, defer: true },
        { src: 'js/theme.js', async: true, defer: true },
      ],
    };
  },
    created() {
     if (!this.$func.getCookie('accessToken')) {
      //console.log('logged out');
      localStorage.removeItem('userData');
    }
  },
};
</script>
