<template>
  <main class="main" id="top">
    <div class="container-fluid" data-layout="container-fluid">
      <Navigation />
      <div class="content">
        <Header />
        <div class="row justify-content-center">
          <div class="col-lg-12">
            <!--start content-->
            <div class="card mb-3">
              <div class="card-header">
                <h5 class="mb-0" data-anchor="data-anchor" id="card-styles">맞춤형 정보 관리</h5>
              </div>
              <div class="card-body bg-light border-top">
                <div class="row align-items-center">
                  <div class="col d-flex align-items-center">
                    <h5 class="card-title">{{ program.title }}</h5>
                  </div>
                  <div class="col-auto">
                    <span :class="`badge rounded-pill d-block p-2 ${$func.getProgramStatusColor(parseInt(program.status))}`"> {{ $func.getProgramStatusLabel(parseInt(program.status)) }}</span>
                    <small>{{ `시작일 : ${$func.getShortDate(program.startDate)}` }}</small>
                  </div>
                </div>
              </div>
              <div class="card-footer border-top">
                <a class="btn btn-falcon-default btn-sm ms-2" :href="`/admin/programs`"><span class="far fa-list-alt me-1"></span>프로그램 목록</a>
              </div>
            </div>
            <div class="card mb-3">
              <div class="card-header">
                <h5 class="mb-0">맞춤형 정보 설정 및 발송</h5>
              </div>
              <div class="card-body bg-white border-top">
                <h5 class="fs-0 mb-3">연령설정</h5>
                <div class="mb-2">
                  <div class="form-check form-check-inline" v-for="ageCode in ageCodeList" :key="ageCode.code">
                    <input class="form-check-input" type="checkbox" :id="`ageCode${ageCode.code}`" v-model="interest" :value="ageCode.code" />
                    <label class="form-check-label" :for="`ageCode${ageCode.code}`">{{ ageCode.label }}</label>
                  </div>
                </div>
                <hr />
                <h5 class="fs-0 mb-3">지역설정</h5>
                <div class="mb-2">
                  <div class="form-check form-check-inline" v-for="areaCode in areaCodeList" :key="areaCode.code">
                    <input class="form-check-input" type="checkbox" :id="`areaCode${areaCode.code}`" v-model="interest" :value="areaCode.code" />
                    <label class="form-check-label" :for="`areaCode${areaCode.code}`">{{ areaCode.label }}</label>
                  </div>
                </div>
                <hr />
                <h5 class="fs-0 mb-3">선호하는 가격대</h5>
                <div class="mb-2">
                  <div class="form-check form-check-inline" v-for="priceCode in priceCodeList" :key="priceCode.code">
                    <input class="form-check-input" type="checkbox" :id="`priceCode${priceCode.code}`" v-model="interest" :value="priceCode.code" />
                    <label class="form-check-label" :for="`priceCode${priceCode.code}`">{{ priceCode.label }}</label>
                  </div>
                </div>
                <hr />
                <h5 class="fs-0 mb-3">프로그램 형태</h5>
                <div class="mb-2">
                  <div class="form-check form-check-inline" v-for="programCode in programCodeList" :key="programCode.code">
                    <input class="form-check-input" type="checkbox" :id="`programCode${programCode.code}`" v-model="interest" :value="programCode.code" />
                    <label class="form-check-label" :for="`programCode${programCode.code}`">{{ programCode.label }}</label>
                  </div>
                </div>
                <hr />
                <h5 class="fs-0 mb-3">교과연계 여부</h5>
                <div class="mb-2">
                  <div class="form-check form-check-inline" v-for="educationCode in educationCodeList" :key="educationCode.code">
                    <input class="form-check-input" type="checkbox" :id="`educationCode${educationCode.code}`" v-model="interest" :value="educationCode.code" />
                    <label class="form-check-label" :for="`educationCode${educationCode.code}`">{{ educationCode.label }}</label>
                  </div>
                </div>
              </div>
              <div class="card-footer border-top">
                <a class="btn btn-falcon-default btn-sm ms-2" @click="saveProgramInterest()"> <span class="far fa-save me-1"></span>저장</a>
                <a class="btn btn-falcon-default btn-sm ms-2" data-bs-toggle="modal" data-bs-target="#modalSendInterestMessage"> <span class="far far fa-bell me-1"></span>발송</a>
              </div>
            </div>
          </div>
        </div>
        <ModalDialog :modalId="'modalSendInterestMessage'" :title="'맞춤형 메시지 발송'" :message="'설정된 옵션으로 맞춤형 메시지를 발송하시겠습니까?'" @DialogOk="sendInterestMessage"></ModalDialog>
        <FooterContent />
      </div>
    </div>
  </main>
</template>

<script>
import Header from '@/components/admin/common/Header.vue';
import Navigation from '@/components/admin/common/Navigation.vue';
import FooterContent from '@/components/admin/common/FooterContent.vue';
// @ is an alias to /src
import ModalDialog from '@/components/common/ModalDialog.vue';
export default {
  name: 'program-option',
  components: {
    Header,
    Navigation,
    FooterContent,
    ModalDialog,
  },
  data() {
    return {
      programId: -1,
      program: {},
      interest: [],
      ageCodeList: [
        { code: 1, label: '3-5세' },
        { code: 2, label: '6-7세' },
        { code: 3, label: '초등학생(1-3학년)' },
        { code: 4, label: '초등학생(4-6학년)' },
        { code: 5, label: '중학생' },
        { code: 6, label: '고등학생' },
        { code: 7, label: '성인' },
      ],
      areaCodeList: [
        { code: 101, label: '서울' },
        { code: 102, label: '부산' },
        { code: 103, label: '대구' },
        { code: 104, label: '인천' },
        { code: 105, label: '광주' },
        { code: 106, label: '대전' },
        { code: 107, label: '울산' },
        { code: 108, label: '세종' },
        { code: 109, label: '경기' },
        { code: 110, label: '강원' },
        { code: 111, label: '충북' },
        { code: 112, label: '충남' },
        { code: 113, label: '전북' },
        { code: 114, label: '전남' },
        { code: 115, label: '경북' },
        { code: 116, label: '경남' },
        { code: 117, label: '제주' },
      ],
      priceCodeList: [
        { code: 201, label: '무료' },
        { code: 202, label: '5만원 미만' },
        { code: 203, label: '5만원 이상' },
        { code: 204, label: '상관없음' },
      ],
      programCodeList: [
        { code: 301, label: '당일 프로그램' },
        { code: 302, label: '1박2일 프로그램' },
        { code: 303, label: '아이만 참여' },
        { code: 304, label: '가족 참여' },
        { code: 305, label: '교육' },
        { code: 306, label: '체험' },
        { code: 307, label: '공연' },
        { code: 308, label: '역사탐방' },
        { code: 309, label: '비대면 교육' },
      ],
      educationCodeList: [
        { code: 401, label: '한국사' },
        { code: 402, label: '국어' },
        { code: 403, label: '수학' },
        { code: 404, label: '영어' },
        { code: 405, label: '사회' },
        { code: 406, label: '과학' },
        { code: 407, label: '예술' },
      ],
    };
  },
  created() {
    //console.log('created - programId : ' + this.$route.params.id);
    this.programId = this.$route.params.id;
  },
  mounted() {
    this.getProgramData();
  },
  methods: {
    getProgramData() {
      //console.log('getProgramData');
      this.$http
        .get('/admin-api/get-program.nolto', {
          params: {
            programId: this.programId,
          },
        })
        .then((response) => {
          //console.log(response);
          if (response.data.error) {
            this.$toast.error(response.data.message);
            return;
          }
          this.program = response.data.program;
          if (this.program.interest) {
            this.interest = JSON.parse(`[${this.program.interest}]`);
          }
          //console.log('interest:', this.interest);
        })
        .catch((error) => {
          console.log(error);
        });
    },

    saveProgramInterest() {
      //console.log('saveProgramInterest');
      let interestParam = JSON.stringify(this.interest);
      interestParam = interestParam.replace('[', '');
      interestParam = interestParam.replace(']', '');
      //console.log('interestParam:', interestParam);
      let params = { programId: this.programId, interest: interestParam };
      this.$http
        .post('/admin-api/save-program-interest.nolto', params)
        .then((response) => {
          //console.log('interest updated:', response);
          if (response.data.error) {
            this.$toast.error(response.data.message);
            return;
          }
          this.$toast.success(response.data.message);
        })
        .catch((error) => {
          console.log(error);
        });
    },

    sendInterestMessage() {
      //console.log('sendInterestMessage');
      if (this.interest.length == 0) {
        this.$toast.warning('선택된 설정이 없습니다.');
        return;
      }
      let interestParam = JSON.stringify(this.interest);
      interestParam = interestParam.replace('[', '');
      interestParam = interestParam.replace(']', '');
      console.log('interestParam:', interestParam);
      let params = { programId: this.programId, interest: interestParam };
      this.$http
        .post('/admin-api/send-interest-message.nolto', params)
        .then((response) => {
          console.log('message sent:', response);
          if (response.data.error) {
            this.$toast.error(response.data.message);
            return;
          }
          this.$toast.success(response.data.message);
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
};
</script>
