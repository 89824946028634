<template>
  <div
    class="modal fade"
    id="modalRefundInfo"
    tabindex="-1"
    role="dialog"
    aria-hidden="true"
  >
    <div
      class="modal-dialog modal-dialog-centered"
      role="document"
      style="max-width: 500px"
    >
      <div class="modal-content position-relative">
        <div class="position-absolute top-0 end-0 mt-2 me-2 z-index-1">
          <button
            class="btn-close btn btn-sm btn-circle d-flex flex-center transition-base"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-header">
          <h5 class="modal-title">환불정보</h5>
        </div>
        <div class="modal-body">
          <h6>은행명</h6>
          <p class="mb-1">{{refundInfo.bank}}</p>
          <h6 class="mt-3">계좌번호</h6>
          <p class="mb-1">{{refundInfo.account}}</p>
          <h6 class="mt-3">예금주</h6>
          <p class="mb-1">{{refundInfo.account_user}}</p>
        </div>
        <div class="modal-footer">
          <button
            class="btn btn-secondary rounded-pill"
            type="button"
            data-bs-dismiss="modal">닫기</button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name:'RefundInfoDialog',
  props: {
    refundInfo: {
      type: Object,
      default() {
        return {}
      },
    },
  },
  created() {
    console.info('.created - refundInfo:', this.refundInfo)
  },
  watch: {
    params: function(newVal, oldVal) {
      console.info('params changed:', newVal)
      this.params = newVal
    },
  },
}
</script>
