<template>
  <div class="d-flex">
    <div class="ms-2">
      <div class="avatar avatar-2xl">
        <img class="rounded-circle" ref="profileImage" :src="userProfileImage" alt="" />
      </div>
    </div>
    <div class="ms-3 flex-1 me-2">
      <div class="mb-1">
        <div class="row justify-content-between">
          <div class="col">
            <span class="text-dark fw-semi-bold">{{programReview.userName}}</span>
          </div>
          <div class="col-auto">
            <p class="fs--1 mb-1">{{createDate}}</p>
          </div>
        </div>
      </div>
      <div class="mb-2">
        <star-rating
          :border-width="1"
          border-color="#d8d8d8"
          :rounded-corners="true"
          :increment="1"
          :star-size="15"
          :read-only="true"
          :show-rating="false"
          :rating="programReview.rating"
          :star-points="[23, 2, 14, 17, 0, 19, 10, 34, 7, 50, 23, 43, 38, 50, 36, 34, 46, 19, 31, 17]"
        >
        </star-rating>
      </div>
      <p class="mb-0">{{programReview.review}}</p>
    </div>
  </div>
</template>
<script>
import moment from 'moment';
import StarRating from 'vue-star-rating';
export default {
  name: 'ProgramReview',
  components: {
    StarRating,
  },
  data() {
    return {};
  },
  props: {
    programReview: {
      type: Object,
      required: true,
      default: {
        id: 0,
        profileImagePath: '',
        uerUid:'',
        userName:'',
        review: '',
        rating: 5,
        createTime: '2024-06-05T09:28:35.215Z',
      },
    },
  },
  created() {
    // console.debug('created - programReview:', this.programReview);
  },
  computed: {
    userProfileImage() {
      return process.env.VUE_APP_BASE_URL + this.programReview.profileImagePath;
    },
    createDate() {
      return moment(this.programReview.createTime).format('YYYY-MM-DD');
    },
  },
  methods: {},
};
</script>
