<template>
  <div class="mb-2">
    <div class="row m-2 ">
      <div class="col-md d-flex">
        <div class="me-2">
          <span class="badge" :class="programInquiry.answer ? 'badge-soft-primary' : 'badge-soft-success'">{{ programInquiry.answer ? '답변완료' : '미답변' }}</span>
        </div>
        <div class="flex-1">
          {{ programInquiry.isPrivacy ? (isMyInquiry ? programInquiry.question : '비밀글입니다.') : programInquiry.question }}
          <span class="badge badge rounded-pill badge-soft-secondary" v-show="programInquiry.isPrivacy">비밀글<span class="fas fa-lock ms-1"></span></span>
        </div>
      </div>
      <div class="col-md-auto ms-auto d-flex justify-content-end">
          <div class="fs--1 text-dark">{{ programInquiry.userName }}</div>
          <div class="fs--1 ms-2">{{ createDate }}</div>
      </div>
    </div>
    <div class="ms-3" v-if="programInquiry.answer && (!programInquiry.isPrivacy || isMyInquiry)">
      <div class="border-dashed-bottom mb-2"></div>
      <div class="row ms-2 me-2">
        <div class="col-md d-flex">
          <div class="me-2">
            <span class="badge badge-soft-primary"><span class="fas fa-angle-right me-2"></span>답변</span>
          </div>
          <div class="flex-1">
            <div class="fs-0">{{ programInquiry.answer }}</div>
          </div>
        </div>
        <div class="col-md-auto ms-auto d-flex justify-content-end">
          <div class="fs--1">{{ updateDate }}</div>
        </div>
      </div>
    </div>
    <div class="d-flex flex-row-reverse me-3 m-1" v-if="isMyInquiry && !programInquiry.answer">
      <div class="row align-items-center">
        <div class="col">
          <button class="btn btn-falcon-default rounded-pill btn-sm" type="button" title="삭제"
                  data-bs-toggle="modal" data-bs-target="#modalDeleteInquiry" @click="onDeleteInquiry">
            <span class="far fa-trash-alt me-1"></span>삭제
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import moment from 'moment';

export default {
  name: 'programInquiry',
  components: {},
  data() {
    return {
      user: null,
    };
  },
  props: {
    programInquiry: {
      type: Object,
      required: true,
      default: {
        id: -1,
        userUid: '',
        userName: '',
        question: '',
        answer: '',
        isPrivacy: false,
        createTime: '1970-01-01',
        updateTime: '1970-01-01',
      },
    },
  },
  created() {
    console.debug('created - programInquiry:', this.programInquiry);
    this.user = this.$func.getUser();
  },
  computed: {
    createDate() {
      return moment(this.programInquiry.createTime).format('YYYY-MM-DD');
    },
    updateDate() {
      return moment(this.programInquiry.createTime).format('YYYY-MM-DD');
    },
    isMyInquiry() {
      return this.user && this.user.uid === this.programInquiry.userUid;
    },
  },
  methods: {
    onDeleteInquiry() {
      //console.log('onDeleteInquiry - programInquiry:', this.programInquiry);
      this.$emit('saveInquiryTemp', this.programInquiry);
    },
  },
};
</script>
