<template>
</template>
<script>
export default {
  name: "LogoutCallback",
  created() {
    //console.log('LogoutCallback');
    localStorage.removeItem('userData');
    this.$func.setCookie('accessToken',null,0);
    this.$router.push("/");
  },
};
</script>