<template>
  <div class="modal fade" id="modalProgramInquiry" tabindex="-1" role="dialog" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered" role="document" style="max-width: 640px">
      <div class="modal-content position-relative">
        <div class="position-absolute top-0 end-0 mt-2 me-2 z-index-1">
          <button class="btn-close btn btn-sm btn-circle d-flex flex-center transition-base" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-header">
          <h5 class="modal-title">문의하기</h5>
        </div>
        <div class="modal-body">
          <div class="mb-3">
            <label class="form-label" for="inputQuestion">질문</label>
            <textarea class="form-control" ref="inputQuestion" id="inputQuestion" v-model="question" rows="3"></textarea>
          </div>
          <div>
            <input class="form-check-input" id="isPrivacy" type="checkbox" v-model="isPrivacy" />
            <label class="form-label ms-2" for="isPrivacy">비밀글</label>
          </div>
        </div>
        <div class="modal-footer">
          <button class="btn btn-secondary rounded-pill" type="button" data-bs-dismiss="modal">취소</button>
          <button class="btn btn-primary rounded-pill" type="button" data-bs-dismiss="modal" :disabled="!isWritable" @click="onClickOk">등록</button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Editor from '@tinymce/tinymce-vue';
export default {
  name: 'ProgramInquiryDialog',
  components: {
    editor: Editor,
  },
  mounted() {
    console.info('onMounted');
    document.getElementById('modalProgramInquiry').addEventListener('shown.bs.modal', function (event) {
      //console.log('inquiryDialog shown');
      document.getElementById('inputQuestion').value = '';
      document.getElementById('isPrivacy').checked = false;
      document.getElementById('inputQuestion').focus();
    });
  },
  data() {
    return {
      isPrivacy: false,
      question: '',
    };
  },
  computed: {
    isWritable() {
      return this.question && this.question.length > 0;
    },
  },
  methods: {
    onClickOk() {
      console.info('onClickOk');
      this.$emit('saveInquiry', { question: this.question, isPrivacy: this.isPrivacy });
    },
  },
};
</script>
