<template>
  <main class="main" id="top">
    <div class="container-fluid" data-layout="container-fluid">
      <Navigation />
      <div class="content">
        <Header />
        <div class="row justify-content-center">
          <div class="col-lg-12">
            <div class="card mb-3">
              <div class="card-header">
                <div class="row justify-content-between">
                  <div class="col">
                    <h3 class="mb-0 d-flex align-items-center">프로그램 목록</h3>
                  </div>
                  <div class="col-auto">
                    <form class="d-flex search-box align-items-center" v-on:submit.prevent>
                      <input class="form-control search-input me-2" type="search" v-model="search" placeholder="프로그램 제목" @keyup.enter="changeSearch" />
                      <span class="fas fa-search search-box-icon"></span>
                    </form>
                  </div>
                </div>
              </div>
              <div class="card-body bg-white border-top">
                <div class="mb-3">
                  <div id="tableProgramList" data-list='{"pagination":true}'>
                    <div class="table-responsive scrollbar">
                      <table class="table table-hover table-bordered table-striped fs--1 mb-0">
                        <thead class="bg-200 text-900">
                          <tr>
                            <th class="text-center" :class="sortClass('ID')"><a href="#" @click.prevent="sort('ID')">ID</a></th>
                            <th class="text-center" :class="sortClass('TITLE')"><a href="#" @click.prevent="sort('TITLE')">제목</a></th>
                            <th class="text-center" :class="sortClass('START_DATE')"><a href="#" @click.prevent="sort('START_DATE')">시작일</a></th>
                            <th class="text-center" :class="sortClass('RATING')"><a href="#" @click.prevent="sort('RATING')">평점</a></th>
                            <th class="text-center" :class="sortClass('WAITING_COUNT')"><a href="#" @click.prevent="sort('WAITING_COUNT')">대기</a></th>
                            <th class="text-center" :class="sortClass('RESERVED_COUNT')"><a href="#" @click.prevent="sort('RESERVED_COUNT')">예약완료</a></th>
                            <th class="text-center" :class="sortClass('REQUEST_CANCEL_COUNT')"><a href="#" @click.prevent="sort('REQUEST_CANCEL_COUNT')">취소요청</a></th>
                            <th class="text-center" :class="sortClass('CANCELLED_COUNT')"><a href="#" @click.prevent="sort('CANCELLED_COUNT')">취소</a></th>
                            <th class="text-center" :class="sortClass('REVIEW_COUNT')"><a href="#" @click.prevent="sort('REVIEW_COUNT')">후기</a></th>
                            <th class="text-center" :class="sortClass('NEW_INQUIRY_COUNT')"><a href="#" @click.prevent="sort('NEW_INQUIRY_COUNT')">새문의</a></th>
                            <th class="text-center" :class="sortClass('STATUS')"><a href="#" @click.prevent="sort('STATUS')">상태</a></th>
                            <th class="text-center"><span style="font-size: smaller">옵션관리</span></th>
                            <th class="text-center"><span style="font-size: smaller">예약관리</span></th>
                            <th class="text-center"><span style="font-size: smaller">보고서</span></th>
                            <th class="text-center"><span style="font-size: smaller">맞춤관리</span></th>
                          </tr>
                        </thead>
                        <tbody class="list">
                          <tr v-for="program in programs" :key="program.id">
                            <td class="text-center">{{ program.id }}</td>
                            <td class="text-nowrap">{{ program.title }}</td>
                            <td class="text-nowrap">{{ $func.getShortDate(program.startDate) }}</td>
                            <td class="text-center">{{ program.rating }}</td>
                            <td class="text-center">
                              <a :href="`/admin/program-reservation/${program.id}`">{{ program.waitingCount }}</a>
                            </td>
                            <td class="text-center">
                              <a :href="`/admin/program-reservation/${program.id}`">{{ program.reservedCount }}</a>
                            </td>
                            <td class="text-center">
                              <a :href="`/admin/program-reservation/${program.id}`">{{ program.requestCancelCount }}</a>
                            </td>
                            <td class="text-center">
                              <a :href="`/admin/program-reservation/${program.id}`">{{ program.cancelledCount }}</a>
                            </td>
                            <td class="text-center">
                              <a :href="`/admin/program-review/${program.id}`">{{ program.reviewCount }}</a>
                            </td>
                            <td class="text-center">
                              <a :href="`/admin/program-inquiry/${program.id}`">{{ program.newInquiryCount }}</a>
                            </td>
                            <td class="text-center">
                              <span :class="`badge rounded-pill d-block p-2 ${$func.getProgramStatusColor(parseInt(program.status))}`">{{ $func.getProgramStatusLabel(parseInt(program.status)) }}</span>
                            </td>
                            <td class="text-center">
                              <a class="btn btn-sm btn-outline-primary rounded-pill" :href="`/admin/program-option/${program.id}`"><span style="font-size: smaller">옵션관리</span></a>
                            </td>
                            <td class="text-center">
                              <a class="btn btn-sm btn-outline-primary rounded-pill" :href="`/admin/program-reservation/${program.id}`"><span style="font-size: smaller">예약관리</span></a>
                            </td>
                            <td class="text-center">
                              <a class="btn btn-sm btn-outline-primary rounded-pill" :href="`/admin/program-report/${program.id}`"><span style="font-size: smaller">보고서</span></a>
                            </td>
                            <td class="text-center">
                              <a class="btn btn-sm btn-outline-primary rounded-pill" :href="`/admin/program-interest/${program.id}`"><span style="font-size: smaller">맞춤관리</span></a>
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                    <div class="d-flex justify-content-center mt-3">
                      <button class="btn btn-sm btn-falcon-default me-1" type="button" title="Previous" v-show="prev" @click="prevRange">
                        <span class="fas fa-chevron-left"></span>
                      </button>
                      <ul class="pagination mb-0">
                        <li :class="currentPage == page ? 'active' : ''" v-for="(page, index) in range" :key="index">
                          <button class="page" type="button" @click="getProgramList(page)">
                            {{ page }}
                          </button>
                        </li>
                      </ul>
                      <button class="btn btn-sm btn-falcon-default ms-1" type="button" title="Next" v-show="next" @click="nextRange">
                        <span class="fas fa-chevron-right"> </span>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="mt-5 text-center" v-show="isLoading">
          <div class="spinner-border text-info" role="status">
            <span class="visually-hidden">Loading...</span>
          </div>
        </div>
        <FooterContent />
      </div>
    </div>
  </main>
</template>

<script>
import Header from '@/components/admin/common/Header.vue';
import Navigation from '@/components/admin/common/Navigation.vue';
import FooterContent from '@/components/admin/common/FooterContent.vue';
const listSize = 10; //페이지별 아이템
const rangeSize = 5; //페이지별 레인지 크기
export default {
  name: 'program-list',
  components: {
    Header,
    Navigation,
    FooterContent,
  },
  data() {
    return {
      isLoading: false,
      programs: [],
      range: [],
      search: '',
      currentSort: 'ID',
      currentSortDir: 'DESC',
      total: 0, //전체아이템수
      currentPage: 1, //현재 페이지
      totalPage: 1, //전체 페이지
      startPage: 1, //시작 페이지
      endPage: 1, //끝 페이지
      currentRange: 1, //현재 레인지
      totalRange: 1, //전체 레인지
      prev: false, //이전 레인지 유무
      next: false, //다음 레인지 유무
    };
  },
  created() {
    this.getProgramList(1);
  },
  watch: {
    search: function (newVal, oldVal) {
      if (!newVal) {
        this.getProgramList(1);
      }
    },
  },
  methods: {
    changeSearch() {
      this.getProgramList(1);
    },
    viewCollection(collectionId) {
      this.$router.push({
        name: 'view-collection',
        params: { id: collectionId },
      });
    },
    prevRange() {
      const prevPage = (this.currentRange - 2) * rangeSize + 1;
      this.getProgramList(prevPage);
    },
    nextRange() {
      const nextPage = this.currentRange * rangeSize + 1;
      this.getProgramList(nextPage);
    },
    sort(field) {
      if (this.currentSort === field) {
        this.currentSortDir = this.currentSortDir === 'ASC' ? 'DESC' : 'ASC';
      } else {
        this.currentSort = field;
        this.currentSortDir = 'ASC';
      }
      this.getProgramList(1);
    },
    sortClass(field) {
      if (this.currentSort === field) {
        return this.currentSortDir === 'ASC' ? 'sorting_asc' : 'sorting_desc';
      } else {
        return 'sorting';
      }
    },
    getProgramList(page) {
      //console.log('getProgramList - page:', page, ', search:', this.search);
      this.isLoading = true;
      this.$http
        .get('/admin-api/get-program-list.nolto', {
          params: {
            listSize: listSize,
            page: page,
            search: this.search,
            sort: this.currentSort,
            sortDir: this.currentSortDir,
          },
        })
        .then((response) => {
          // Math.floor
          // 소수점 첫째 자리에서 내림(버림). 3.1은 3, -1.1은 -2가 됩니다.
          // Math.ceil
          // 소수점 첫째 자리에서 올림. 3.1은 4, -1.1은 -1이 됩니다.
          // Math.round
          // 소수점 첫째 자리에서 반올림. 3.1은 3, 3.6은 4, -1.1은 -1이 됩니다.
          // Math.trunc (Internet Explorer에서는 지원하지 않음)
          // 소수부를 무시. 3.1은 3이 되고 -1.1은 -1이 됩니다.

          //console.log('response:', response);
          if (response.data.error) {
            this.$toast.error(response.data.message);
            return;
          }
          this.total = response.data.total;
          this.currentPage = response.data.page;
          this.currentSort = response.data.sort;
          this.currentSortDir = response.data.sortDir;
          this.programs = response.data.items;
          this.totalPage = Math.ceil(this.total / listSize);
          this.totalRange = Math.ceil(this.total / (listSize * rangeSize));
          this.currentRange = Math.ceil(this.currentPage / rangeSize);
          this.startPage = rangeSize * (this.currentRange - 1) + 1;
          this.endPage = this.currentRange * rangeSize > this.totalPage ? this.totalPage : this.currentRange * rangeSize;
          this.prev = this.currentRange > 1;
          this.next = this.currentRange < this.totalRange;
          this.range = [];
          let i = 1;
          for (i = this.startPage; i <= this.endPage; i++) this.range.push(i);
          // //console.log(
          //   'currentPage:',
          //   this.currentPage,
          //   'total:',
          //   this.total,
          //   'totalPage:',
          //   this.totalPage,
          //   'totalRange:',
          //   this.totalRange,
          //   'currentRange:',
          //   this.currentRange,
          //   'startPage:',
          //   this.startPage,
          //   'endPage:',
          //   this.endPage,
          //   'prev:',
          //   this.prev,
          //   'next:',
          //   this.next
          // );
        })
        .catch((error) => {
          console.log(error);
          
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
  },
};
</script>
