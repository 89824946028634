<template>
  <div
    class="modal fade"
    id="modalUniqueness"
    tabindex="-1"
    role="dialog"
    aria-hidden="true"
  >
    <div
      class="modal-dialog modal-dialog-centered"
      role="document"
      style="max-width: 500px"
    >
      <div class="modal-content position-relative">
        <div class="position-absolute top-0 end-0 mt-2 me-2 z-index-1">
          <button
            class="btn-close btn btn-sm btn-circle d-flex flex-center transition-base"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-header">
          <h5 class="modal-title">특이사항</h5>
        </div>
        <div class="modal-body">
          <h6>내용</h6>
          <p class="mb-1">{{uniqueness}}</p>
        </div>
        <div class="modal-footer">
          <button
            class="btn btn-secondary rounded-pill"
            type="button"
            data-bs-dismiss="modal">닫기</button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name:'UniquenessDialog',
  props: {
    uniqueness: {
      type: String,
      default() {
        return ''
      },
    },
  },
  created() {
    console.info('.created - uniqueness:', this.uniqueness)
  },
  watch: {
    uniqueness: function(newVal, oldVal) {
      console.info('uniqueness changed:', newVal)
      this.uniqueness = newVal
    },
  },
}
</script>
