<template>
  <main class="main" id="top">
    <div class="container-fluid" data-layout="container-fluid">
      <Navigation />
      <div class="content">
        <Header />
        <div class="row justify-content-center">
          <div class="col-lg-12">
            <!--start content-->
            <div class="card mb-3">
              <div class="card-header">
                <h5 class="mb-0" data-anchor="data-anchor" id="card-styles">프로그램 옵션 관리</h5>
              </div>
              <div class="card-body bg-light border-top">
                <div class="row align-items-center">
                  <div class="col d-flex align-items-center">
                    <h5 class="card-title">{{ program.title }}</h5>
                  </div>
                  <div class="col-auto">
                    <span :class="`badge rounded-pill d-block p-2 ${$func.getProgramStatusColor(parseInt(program.status))}`"> {{ $func.getProgramStatusLabel(parseInt(program.status)) }}</span>
                    <small>{{ `시작일 : ${$func.getShortDate(program.startDate)}` }}</small>
                  </div>
                </div>
              </div>
              <div class="card-footer border-top">
                <a class="btn btn-falcon-default btn-sm ms-2" :href="`/admin/programs`"><span class="far fa-list-alt me-1"></span>프로그램 목록</a>
              </div>
            </div>
            <div class="card mb-3">
              <div class="card-header">
                <h5 class="mb-0">옵션</h5>
              </div>
              <div class="card-body bg-white border-top">
                <h5 class="fs-0 mb-3">기본 옵션</h5>
                <div class="mb-3">
                  <OptionEdit ref="option1Label" :optionNo="1" :label="program.option1Label" @onChangeOption="updateOptionLabel" />
                </div>
                <div class="row justify-content-between mb-2">
                  <div class="col">
                    <h5 class="fs-0 mb-3">옵션값</h5>
                  </div>
                  <div class="col-auto">
                    <a class="btn btn-falcon-default btn-sm" @click="newOptionValue(1)"><span class="fas fa-plus-circle me-1"></span>새로운 옵션값</a>
                  </div>
                </div>
                <div class="mb-2" v-show="option1Values.length == 0">
                  <p class="mb-0">기본옵션값 없음</p>
                </div>
                <div class="mb-2" v-for="optionValue in option1Values" :key="optionValue.id">
                  <OptionValueEdit :optionValue="optionValue" @deleteOptionValue="confirmDeleteOptionValue" @changeOptionValue="changeOptionValue" />
                </div>
                <hr />
                <h5 class="fs-0 mb-3">추가 옵션</h5>
                <div class="mb-3">
                  <OptionEdit ref="option2Label" :optionNo="2" :label="program.option2Label" @onChangeOption="updateOptionLabel" />
                </div>
                <div class="row justify-content-between mb-2">
                  <div class="col">
                    <h5 class="fs-0 mb-3">옵션값</h5>
                  </div>
                  <div class="col-auto">
                    <a class="btn btn-falcon-default btn-sm" @click="newOptionValue(2)"><span class="fas fa-plus-circle me-1"></span>새로운 옵션값</a>
                  </div>
                </div>
                <div class="mb-2" v-show="option2Values.length == 0">
                  <p class="mb-0">추가옵션값 없음 <small>(기본옵션만 사용할 경우 비워두세요)</small></p>
                </div>
                <div class="mb-2" v-for="optionValue in option2Values" :key="optionValue.id">
                  <OptionValueEdit :optionValue="optionValue" @deleteOptionValue="confirmDeleteOptionValue" @changeOptionValue="changeOptionValue" />
                </div>
              </div>

              <div class="card-footer border-top">
                <a class="btn btn-falcon-default btn-sm ms-2" data-bs-toggle="modal" data-bs-target="#modalFetchProgramProduct"> <span class="far fa-arrow-alt-circle-down me-1"></span>옵션으로 상품적용</a>
              </div>
            </div>
            <div class="card mb-3">
              <div class="card-header">
                <h5 class="mb-0">프로그램 상품</h5>
              </div>
              <div class="card-body bg-white border-top">
                <table class="table table-hover table-bordered table-striped fs--1 mb-0">
                  <thead class="bg-200 text-900">
                  <tr>
                    <th class="text-center">ID</th>
                    <th class="text-center">기본옵션</th>
                    <th class="text-center">추가옵션</th>
                    <th class="text-center">수량</th>
                    <th class="text-center">추가금액</th>
                    <th class="text-center">진행일자</th>
                    <th class="text-center">판매상태</th>
                    <th class="text-center">사용중</th>
                  </tr>
                  </thead>
                  <tbody class="list">
                  <tr v-for="product in products" :key="product.id">
                    <td class="text-center">{{ product.id }}</td>
                    <td class="text-center">{{ product.option1Value }}</td>
                    <td class="text-center">{{ product.option2Value }}</td>
                    <td class="align-middle">
                      <input class="form-control form-control-sm border-0 text-end" style="width: 60px" type="text" v-model="product.quantity" @keypress="$func.inputNumberOnly($event)" @change="updateProgramProduct(product)" />
                    </td>
                    <td class="align-middle">
                      <input class="form-control form-control-sm border-0 text-end" style="width: 100px" type="text" v-model="product.extraPrice" @keypress="$func.inputNumberOnly($event)" @change="updateProgramProduct(product)" />
                    </td>
                    <td class="align-middle">
                      <datepicker
                          input-class="form-control form-control-sm border-0 bg-white text-center datepick"
                          v-model="product.endDate"
                          :language="ko"
                          :format="dateFormat"
                          :highlighted="highlighted"
                          @closed="updateProgramProduct(product)"
                      ></datepicker>
                    </td>
                    <td class="align-middle">
                      <select class="form-select form-select-sm border-0" style="width: 100px" aria-label="판매상태" v-model="product.isSoldOut" @change="updateProgramProduct(product)">
                        <option value="true">매진</option>
                        <option value="false">판매중</option>
                      </select>
                    </td>
                    <td class="align-middle">
                      <div class="form-check form-switch d-flex align-items-center justify-content-center">
                        <input class="form-check-input" type="checkbox" v-model="product.isUse" @change="updateProgramProduct(product)" />
                      </div>
                    </td>
                  </tr>
                  </tbody>
                </table>
                <div class="mt-4 mb-4 text-center" v-show="products.length == 0">
                  <p class="mb-0">프로그램 상품 없음</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <ModalDialog
            :modalId="'modalDeleteOptionValue'"
            :title="'옵션값 삭제'"
            :message="`<p class='fw-bold'>` + tempOptionValue.value + `</p>옵션값이 삭제되면 연결된 상품들도 함께 삭제됩니다.<br>계속 진행하시겠습니까?`"
            :params="tempOptionValue"
            :okButton="'삭제'"
            @DialogOk="updateOptionValue"
        ></ModalDialog>
        <ModalDialog :modalId="'modalFetchProgramProduct'" :title="'프로그램 상품적용'" :message="'설정된 옵션으로 상품적용을 하시겠습니까?'" @DialogOk="fetchProgramProduct"></ModalDialog>
        <FooterContent />
      </div>
    </div>
  </main>
</template>

<script>
import Header from '@/components/admin/common/Header.vue';
import Navigation from '@/components/admin/common/Navigation.vue';
import FooterContent from '@/components/admin/common/FooterContent.vue';
// @ is an alias to /src
import ModalDialog from '@/components/common/ModalDialog.vue';
import OptionEdit from '@/components/admin/program/OptionEdit.vue';
import OptionValueEdit from '@/components/admin/program/OptionValueEdit.vue';
import { ko } from 'vuejs-datepicker/dist/locale';
import moment from 'moment';
import Datepicker from 'vuejs-datepicker';
export default {
  name: 'program-option',
  components: {
    Header,
    Navigation,
    FooterContent,
    ModalDialog,
    OptionEdit,
    OptionValueEdit,
    moment,
    Datepicker,
  },
  data() {
    return {
      programId: -1,
      program: {},
      option1Values: [],
      option2Values: [],
      products: [],
      tempOptionValue: {},
      afterNew: false,
      afterNewOptionNo: -1,
      ko: ko,
      highlighted: {
        dates: [
          // Highlight an array of dates
          new Date(),
        ],
      },
    };
  },
  created() {
    //console.log('created - programId : ' + this.$route.params.id);
    this.programId = this.$route.params.id;
    this.getProgramOptionData();
  },
  methods: {
    getProgramOptionData() {
      //console.log('getProgramOptionData');
      this.$http
          .get('/admin-api/get-program-option-data.nolto', {
            params: {
              programId: this.programId,
            },
          })
          .then((response) => {
            //console.log(response);
            if (response.data.error) {
              this.$toast.error(response.data.message);
              return;
            }
            this.program = response.data.program;
            this.option1Values = response.data.option1Values;
            this.option2Values = response.data.option2Values;
            this.products = response.data.products;
          })
          .catch((error) => {
            console.log(error);
          });
    },

    getProgramData() {
      //console.log('getProgramData');
      this.$http
          .get('/admin-api/get-program.nolto', {
            params: {
              programId: this.programId,
            },
          })
          .then((response) => {
            //console.log(response);
            if (response.data.error) {
              this.$toast.error(response.data.message);
              return;
            }
            this.program = response.data.program;
          })
          .catch((error) => {
            console.log(error);
          });
    },

    getProgramOptionValues() {
      //console.log('getProgramOptionValues');
      this.$http
          .get('/admin-api/get-program-option-value-list.nolto', {
            params: {
              programId: this.programId,
            },
          })
          .then((response) => {
            //console.log(response);
            if (response.data.error) {
              this.$toast.error(response.data.message);
              return;
            }
            this.option1Values = response.data.option1Values;
            this.option2Values = response.data.option2Values;
            if (this.afterNew && this.afterNewOptionNo > 0) {
              this.newOptionValue(this.afterNewOptionNo);
              this.afterNew = false;
              this.afterNewOptionNo = -1;
            }
          })
          .catch((error) => {
            console.log(error);
          });
    },

    updateOptionLabel(optionNo, label) {
      //console.log('updateOptionLabel-optionNo:', optionNo, ', label:', label);
      this.$http
          .post('/admin-api/update-program-option-label.nolto', {
            programId: this.programId,
            optionNo: optionNo,
            label: label,
          })
          .then((response) => {
            //console.log(response);
            if (response.data.error) {
              this.$toast.error(response.data.message);
              return;
            }
            this.getProgramData();
            this.$toast.success(response.data.message);
          })
          .catch((error) => {
            console.log(error);
          });
    },

    newOptionValue(optionNo) {
      //console.log('newOptionValue-optionNo:', optionNo);
      if (optionNo == 1) {
        this.option1Values.push({ id: -1, programId: this.programId, optionNo: optionNo, del: false, value: '' });
      } else {
        if (this.option1Values.length == 0) {
          this.$toast.warning('먼저 기본옵션값을 등록하세요.');
          this.option1Values.push({ id: -1, programId: this.programId, optionNo: 1, del: false, value: '' });
        } else {
          this.option2Values.push({ id: -1, programId: this.programId, optionNo: optionNo, del: false, value: '' });
        }
      }
    },

    changeOptionValue(optionValue, afterNew = false) {
      //console.log('changeOptionValue:', optionValue);
      if (optionValue.id == -1 && optionValue.value == '') {
        this.getProgramOptionValues();
      } else {
        this.updateOptionValue(optionValue);
        if (afterNew) {
          this.afterNew = true;
          this.afterNewOptionNo = optionValue.optionNo;
        }
      }
    },

    confirmDeleteOptionValue(optionValue) {
      //console.log('confirmDeleteOptionValue:', optionValue);
      this.tempOptionValue = optionValue;
      this.tempOptionValue.del = true;
    },

    updateOptionValue(optionValue) {
      //console.log('updateOptionValue:', optionValue);
      this.$http
          .post('/admin-api/update-program-option-value.nolto', optionValue)
          .then((response) => {
            //console.log('optionValue is updated:', response);
            if (response.data.error) {
              this.$toast.error(response.data.message);
              return;
            }
            this.getProgramOptionValues();
            this.getProgramProductList();
            this.$toast.success(response.data.message);
          })
          .catch((error) => {
            //console.log('error.message:', error.response.error);
          });
    },

    fetchProgramProduct() {
      //console.log('fetchProgramProduct');
      if (this.program.option1Label == '') {
        this.$toast.warning('기본옵션은 공백일 수 없습니다.');
        this.$refs.option1Label.setFocus();
        return;
      }

      if (this.option1Values.length == 0) {
        this.$toast.warning('1개 이상의 기본옵션값을 등록하세요.');
        this.newOptionValue(1);
        return;
      }

      if (this.program.option2Label == '' && this.option2Values.length > 0) {
        this.$toast.warning('추가옵션명을 입력하세요.');
        this.$refs.option2Label.setFocus();
        return;
      }

      this.$http
          .post('/admin-api/fetch-program-product.nolto', { programId: this.programId })
          .then((response) => {
            //console.log('products are fetched:', response);
            if (response.data.error) {
              this.$toast.error(response.data.message);
              return;
            }
            this.getProgramProductList();
            this.$toast.success(response.data.message);
          })
          .catch((error) => {
            console.log(error);
          });
    },

    getProgramProductList() {
      //console.log('getProgramProductList');
      this.$http
          .get('/admin-api/get-program-product-list.nolto', {
            params: {
              programId: this.programId,
            },
          })
          .then((response) => {
            //console.log('response:', response);
            if (response.data.error) {
              this.$toast.error(response.data.message);
              return;
            }
            this.products = response.data.products;
          })
          .catch((error) => {
            console.log(error);
          });
    },

    updateProgramProduct(product) {
      //console.log('updateProgramProduct',product);
      this.$http
          .post('/admin-api/update-program-product.nolto', product)
          .then((response) => {
            //console.log('product updated:', response);
            if (response.data.error) {
              this.$toast.error(response.data.message);
              return;
            }
            this.getProgramProductList();
            // this.$toast.success(response.data.message);
          })
          .catch((error) => {
            console.log(error);
          });
    },
    dateFormat(date) {
      return moment(date).format('yyyy-MM-DD');
    },

    validateLinkDate(date) {
      if (this.$func.fromNowDays(date) >= 0) {
        this.$toast.warning('링크 만료일자는 오늘 이후로 선택해야합니다.');
      }
    },
  },
};
</script>
