<template>
  <nav class="navbar navbar-light navbar-vertical navbar-expand-lg">
    <div class="d-flex align-items-center">
      <div class="toggle-icon-wrapper">
        <button class="btn navbar-toggler-humburger-icon navbar-vertical-toggle" data-bs-toggle="tooltip" data-bs-placement="left" title="Toggle Navigation">
          <span class="navbar-toggle-icon"><span class="toggle-line"></span></span>
        </button>
      </div>
      <a class="navbar-brand" href="/">
        <div class="d-flex align-items-center py-3">
          <img class="me-2" src="/img/logo.svg" alt="" width="48" />
        </div>
      </a>
    </div>
    <div class="collapse navbar-collapse" id="navbarVerticalCollapse">
      <div class="navbar-vertical-content scrollbar">
        <ul class="navbar-nav flex-column mb-3" id="navbarVerticalNav">
          <!-- parent pages-->
          <a class="nav-link" href="/admin/dashboard.nolto" role="button" aria-expanded="false" v-if="this.$func.isAdmin()">
            <div class="d-flex align-items-center">
              <span class="nav-link-icon"><span class="fas fa-user-cog"></span></span><span class="nav-link-text ps-1">기존관리자</span>
            </div>
          </a>
          <!-- label-->
          <div class="row navbar-vertical-label-wrapper mt-3 mb-2">
            <div class="col ps-0">
              <hr class="mb-0 navbar-vertical-divider" />
            </div>
          </div>

          <a class="nav-link" :class="'/admin/programs' === $route.path ? ' active' : ''" href="/admin/programs" role="button" aria-expanded="false" v-if="$func.isLoggedIn()">
            <div class="d-flex align-items-center">
              <span class="nav-link-icon"><span class="fas fa-landmark"></span></span><span class="nav-link-text ps-1">프로그램 관리</span>
            </div>
          </a>

          <a class="nav-link" role="button" aria-expanded="false" @click="logout()" v-if="$func.isLoggedIn()">
            <div class="d-flex align-items-center">
              <span class="nav-link-icon"><span class="fas fa-sign-out-alt"></span></span><span class="nav-link-text ps-1">로그아웃</span>
            </div>
          </a>
        </ul>
      </div>
    </div>
  </nav>
</template>
<script>
export default {
  name: 'Navigation',
  data() {
    return {
      user: {},
    };
  },
  created() {
    console.debug('path:' + this.$route.path);
    this.user = this.$func.getUser();
  },
  methods: {
    logout() {
      if (process.env.NODE_ENV == 'remote') {
        this.$router.push('/logout');
      } else {
        window.location.href = '/logout.nolto';
      }
    },
  },
};
</script>
