<template>
  <main class="main" id="top">
    <div class="container-fluid" data-layout="container-fluid">
      <Navigation />
      <div class="content">
        <Header />

    <div class="row justify-content-center">
      <div class="col-lg-12">
        <div class="card mb-3">
          <div class="card-header">
            <div class="row justify-content-between">
              <div class="col">
                <h3 class="mb-0 d-flex align-items-center">사용자 목록</h3>
              </div>
              <div class="col-auto">
                <form class="d-flex search-box align-items-center" v-on:submit.prevent>
                  <input
                    class="form-control search-input me-2"
                    type="search"
                    v-model="search"
                    placeholder="사용자 이름"
                    @keyup.enter="changeSearch"
                  />
                  <span class="fas fa-search search-box-icon"></span>
                </form>
              </div>
            </div>
          </div>
          <div class="card-body bg-white border-top">
            <div class="mb-3">
              <div class="row mb-2">
                <div class="col">
                  <h5 class="fs-0 mb-3">전체회원수 : {{total}} 명</h5>
                </div>
              </div>
              <div id="tableUserList" data-list='{"pagination":true}'>
                <div class="table-responsive scrollbar">
                  <table class="table table-hover table-bordered table-striped fs--1 mb-0">
                    <thead class="bg-200 text-900">
                      <tr>
                        <th class="text-nowrap" :class="sortClass('NAME')"><a href="#" @click.prevent="sort('NAME')">이름</a></th>
                        <th class="text-nowrap" >이메일</th>
                        <th class="text-nowrap" >연락처</th>
                        <th class="text-center" :class="sortClass('BIRTH_YEAR')"><a href="#" @click.prevent="sort('BIRTH_YEAR')">태어난해</a></th>
                        <th class="text-center" :class="sortClass('SOURCE')"><a href="#" @click.prevent="sort('SOURCE')">계정종류</a></th>
                        <th class="text-center" :class="sortClass('CREATE_TIME')"><a href="#" @click.prevent="sort('CREATE_TIME')">가입일</a></th>
                        <th class="text-center">자녀정보</th>
                      </tr>
                    </thead>
                    <tbody class="list">
                      <tr v-for="user in users" :key="user.id">
                        <td class="text-nowrap">{{ user.name }}</td>
                        <td class="text-nowrap">{{ user.email }}</td>
                        <td class="text-nowrap">{{ user.contact }}</td>
                        <td class="text-center">{{ user.birthYear }}</td>
                        <td class="text-center">{{ user.source }}</td>
                        <td class="text-center">
                          {{ $func.getShortDate(user.createTime) }}
                        </td>
                        <td class="text-nowrap">{{user.interest}}</td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <div class="d-flex justify-content-center mt-3">
                  <button
                    class="btn btn-sm btn-falcon-default me-1"
                    type="button"
                    title="Previous"
                    v-show="prev"
                    @click="prevRange"
                  >
                    <span class="fas fa-chevron-left"></span>
                  </button>
                  <ul class="pagination mb-0">
                    <li :class="currentPage == page ? 'active' : ''" v-for="(page, index) in range" :key="index">
                      <button class="page" type="button" @click="getUserList(page)">
                        {{ page }}
                      </button>
                    </li>
                  </ul>
                  <button
                    class="btn btn-sm btn-falcon-default ms-1"
                    type="button"
                    title="Next"
                    v-show="next"
                    @click="nextRange"
                  >
                    <span class="fas fa-chevron-right"> </span>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="mt-5 text-center" v-show="isLoading">
      <div class="spinner-border text-info" role="status">
        <span class="visually-hidden">Loading...</span>
      </div>
    </div>
        <FooterContent />
      </div>
    </div>
  </main>
</template>

<script>
import Header from '@/components/admin/common/Header.vue';
import Navigation from '@/components/admin/common/Navigation.vue';
import FooterContent from '@/components/admin/common/FooterContent.vue';
const listSize = 10; //페이지별 아이템
const rangeSize = 5; //페이지별 레인지 크기
export default {
    name:'user-list',
  components: {
    Header,
    Navigation,
    FooterContent,
  },
  data() {
    return {
      isLoading: false,
      users: [],
      range: [],
      search: '',
      currentSort:'CREATE_TIME',
      currentSortDir:'DESC',
      total: 0, //전체아이템수
      currentPage: 1, //현재 페이지
      totalPage: 1, //전체 페이지
      startPage: 1, //시작 페이지
      endPage: 1, //끝 페이지
      currentRange: 1, //현재 레인지
      totalRange: 1, //전체 레인지
      prev: false, //이전 레인지 유무
      next: false, //다음 레인지 유무
    };
  },
  created() {
    this.getUserList(1);
  },
  watch: {
    search: function(newVal, oldVal) {
      if (!newVal) {
        this.getUserList(1);
      }
    },
  },
  methods: {
    changeSearch() {
      this.getUserList(1);
    },
    viewCollection(collectionId) {
      this.$router.push({
        name: 'view-collection',
        params: { id: collectionId },
      });
    },
    prevRange() {
      const prevPage = (this.currentRange - 2) * rangeSize + 1;
      this.getUserList(prevPage);
    },
    nextRange() {
      const nextPage = this.currentRange * rangeSize + 1;
      this.getUserList(nextPage);
    },
    sort(field) {
      if (this.currentSort === field) {
        this.currentSortDir = this.currentSortDir === 'ASC' ? 'DESC': 'ASC';
      } else {
        this.currentSort = field;
        this.currentSortDir = 'ASC';
      }
      this.getUserList(1);
    },
    sortClass(field) {
      if (this.currentSort === field) {
        return this.currentSortDir === 'ASC' ? 'sorting_asc':'sorting_desc';
      } else {
        return 'sorting';
      }
    },
    getUserList(page) {
      //console.log('getUserList - page:', page, ', search:', this.search);
      this.isLoading = true;
      this.$http
        .get('/admin-api/get-user-list.nolto', {
          params: {
            listSize: listSize,
            page: page,
            search: this.search,
            sort: this.currentSort,
            sortDir: this.currentSortDir,
          },
        })
        .then((response) => {
          // Math.floor
          // 소수점 첫째 자리에서 내림(버림). 3.1은 3, -1.1은 -2가 됩니다.
          // Math.ceil
          // 소수점 첫째 자리에서 올림. 3.1은 4, -1.1은 -1이 됩니다.
          // Math.round
          // 소수점 첫째 자리에서 반올림. 3.1은 3, 3.6은 4, -1.1은 -1이 됩니다.
          // Math.trunc (Internet Explorer에서는 지원하지 않음)
          // 소수부를 무시. 3.1은 3이 되고 -1.1은 -1이 됩니다.

          //console.log('getUserList - response:', response);
          if (response.data.error) {
            this.$toast.error(response.data.message);
            return;
          }
          this.total = response.data.total;
          this.currentPage = response.data.page;
          this.currentSort = response.data.sort;
          this.currentSortDir = response.data.sortDir;
          this.users = response.data.items;
          this.totalPage = Math.ceil(this.total / listSize);
          this.totalRange = Math.ceil(this.total / (listSize * rangeSize));
          this.currentRange = Math.ceil(this.currentPage / rangeSize);
          this.startPage = rangeSize * (this.currentRange - 1) + 1;
          this.endPage =
            this.currentRange * rangeSize > this.totalPage ? this.totalPage : this.currentRange * rangeSize;
          this.prev = this.currentRange > 1;
          this.next = this.currentRange < this.totalRange;
          this.range = [];
          let i = 1;
          for (i = this.startPage; i <= this.endPage; i++) this.range.push(i);
          // //console.log(
          //   'currentPage:',
          //   this.currentPage,
          //   'total:',
          //   this.total,
          //   'totalPage:',
          //   this.totalPage,
          //   'totalRange:',
          //   this.totalRange,
          //   'currentRange:',
          //   this.currentRange,
          //   'startPage:',
          //   this.startPage,
          //   'endPage:',
          //   this.endPage,
          //   'prev:',
          //   this.prev,
          //   'next:',
          //   this.next
          // );
        })
        .catch((error) => {
          console.log(error);
          
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
  },
};
</script>
