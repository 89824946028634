<template>
  <main class="main" id="top">
    <div class="container" data-layout="container">
      <Navigation />
      <nav class="mt-3 mb-3" aria-label="breadcrumb">
        <ol class="breadcrumb">
          <li class="breadcrumb-item">
            <a href="/">Home</a>
          </li>
          <li class="breadcrumb-item active" aria-current="page">예약내역</li>
        </ol>
      </nav>
      <div class="content">
        <div class="row flex-between-center mt-3 mb-3 mb-lg-2 border-bottom">
          <div class="col-sm-auto mb-2">
            <h3 class="mt-3 ms-3">예약내역</h3>
          </div>
          <div class="col-sm-auto">
            <form class="row gx-2 align-items-center">
              <div class="col-auto"><small>진행상태</small></div>
              <div class="col-auto">
                <select class="form-select form-select-sm" v-model="status" @change="getReservationListChange()">
                  <option value="2">예약 완료</option>
                  <option value="1">입금대기중</option>
                  <option value="3">환불 요청</option>
                  <option value="4">취소</option>
                </select>
              </div>
            </form>
          </div>
        </div>
        <div v-if="isLoading && reservations.length !== 0" class="p-1" v-for="reservation in reservations" :key="reservation.ID">
          <ReservationItem @CancelEmit="cancelEmit" :reservation="reservation" />
        </div>
        <div class="mt-3 mb-3 text-center">
          <button :class="`btn btn-falcon-primary rounded-pill btn-sm ${!moreToggle ? 'disabled' : ''}`" type="button" href="#" @click="getNextPage()">더보기</button>
        </div>
        <div class="mt-5 text-center" v-show="reservations == null || reservations.length === 0">
          <h4 class="text-secondary"><span class="fas fa-info-circle fs-3 me-2"></span>예약된 내역이 없습니다.</h4>
        </div>
      </div>
      <FooterContent />
    </div>
  </main>
</template>
<script>
// @ is an alias to /src
import Navigation from '@/components/common/Navigation.vue';
import FooterContent from '@/components/common/FooterContent.vue';
import ReservationItem from '@/components/order/ReservationItem.vue';
const listSize = 5;
export default {
  name: 'CartList',
  components: {
    Navigation,
    FooterContent,
    ReservationItem,
  },
  data() {
    return {
      limit: 50,
      isLoading: false,
      page: 1,
      search: '',
      total: 0,
      status: '2',
      user: null,
      isPaging: false,
      reservations: [
        // {
        //   id: 0,
        //   programTitle: '오감체험 제목',
        //   programId: 0,
        //   programTime: '10월 26일(목) 18:00 ~ 19:00',
        //   imagePath: '/v2/resources/images/a9f1989e-9111-47ea-9f62-1a70b18e767e.jpg',
        //   status: 1,
        //   quantity: 1,
        //   payout: 0,
        //   reservationTime: '2024-06-11T09:58:37.522Z',
        // }
      ],
      moreToggle: true,
    };
  },
  created() {
    //console.log('onCreated');
    this.user = this.$func.getUser();
    this.getReservationList();
  },
  methods: {
    cancelEmit(reservation){

      this.$http
          .delete(`/reservation/${reservation.id}`)
          .then((response) => {
            if(response.status === 200 || response.status === 201){

              this.$toast.success("예약이 취소되었습니다.");

              this.getReservationListChange();
            } else {
              // console.log(response);
              this.$toast.success("서버와의 연결이 불안정 합니다.");
            }
          })
          .catch((error) => {
            console.log(error);
            this.$toast.error("서버와의 연결이 불안정 합니다.");

          });
    },
    getReservationListChange(){
      this.reservations = [];
      this.isPaging = false;
      const paramsObj = {
        skip: this.reservations.length,
        limit: this.limit,
        status: this.status,
      }
      if(this.status === '0'){
        delete paramsObj.status;
      } else {
        paramsObj.status = this.status;
      }
      // console.log('paramsObj:', paramsObj);

      this.$http
          .get('/my-page/reservation', {
            params: {
              ...paramsObj
            },
          })
          .then((response) => {
            //console.log('response:', response);
            if (response.data.error) {
              this.$toast.error(response.data.message);
              return;
            }

            this.reservations = [...response.data.reverse()];
            this.isPaging = true;


            this.moreToggle = this.reservations.length === this.limit;
            this.isLoading = true;

          })
          .catch((error) => {
            console.log(error);

          });
    },
    getReservationList() {
      //console.log('getReservationList - listSize:', listSize, ', page:', page);
      const paramsObj = {
        skip: this.reservations.length,
        limit: this.limit,
        status: this.status,
      }
      if(this.status === '0'){
        delete paramsObj.status;
      } else {
        paramsObj.status = this.status;
      }
      // console.log('paramsObj:', paramsObj);

      this.$http
        .get('/my-page/reservation', {
          params: {
            ...paramsObj
          },
        })
        .then((response) => {
          //console.log('response:', response);
          if (response.data.error) {
            this.$toast.error(response.data.message);
            return;
          }

          if(!this.isPaging){
            // console.log("첫번째");
            this.reservations = response.data.reverse();
            this.isPaging = true;
          } else {
            // console.log('페이징');
            this.reservations = [...this.reservations, ...response.data.reverse()];
          }

          this.moreToggle = this.reservations.length === this.limit;
          this.isLoading = true;

        })
        .catch((error) => {
          console.log(error);

        });
    },
    getNextPage() {
      this.getReservationList();
    },
  },
};
</script>
