// 공통 함수
import moment from 'moment';
import config from '../config';
export const commonFunc = {

  setCookie(name, value,hours=1) {
    let date = new Date();
    date.setTime(date.getTime() + hours*60*60*1000);
    document.cookie = name + '=' + value + ';expires=' + date.toUTCString() + ';path=/';
  },

  getCookie(name) {
    let value = document.cookie.match('(^|;) ?' + name + '=([^;]*)(;|$)');
    return value? value[2] : null;
  },

  setPath: (path) => {
    localStorage.setItem('path', path);
  },
  getPath: () => {
    return localStorage.getItem('path');
  },
  isLoggedIn: () => {
    return commonFunc.getCookie('accessToken') && localStorage.getItem('userData');
  },

  isAdmin: () => {
    try {
      const user = JSON.parse(localStorage.getItem('userData'));
      return user.role.indexOf('ADMIN') > -1;
    } catch (e) {
      return false;
    }
  },

  isManager: () => {
    try {
      const user = JSON.parse(localStorage.getItem('userData'));
      return user.role.indexOf('MANAGER') > -1;
    } catch (e) {
      return false;
    }
  },

  getUser: () => {
    try {
      return JSON.parse(localStorage.getItem('userData'));
    } catch (e) {
      return {}
    }
  },

  fromNowDays(date) {
    const today = moment(moment().format('yyyy-MM-DD'));
    const selectedDate = moment(moment(date).format('yyyy-MM-DD'));
    const diff = today.diff(selectedDate, 'days');
    return diff;
  },

  getImageUrl(fileName) {
    if (fileName) {
      return location.host == 'localhost:8080' ? `http://localhost/images/${fileName}` : `http://localhost:8080/images/${fileName}`;
    } else {
      //TODO: 기본이미지 변경하기
      return '/img/no-image.png';
    }
  },
  getfileSize(size) {
    const unit = ['bytes', 'kB', 'MB', 'GB', 'TB', 'PB'];
    var value = Math.floor(Math.log(size) / Math.log(1024));
    return (size / Math.pow(1024, value)).toFixed(2) + ' ' + unit[value];
  },

  validateEmail(email) {
    const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(email);
  },

  validatePassword(password) {
    const patternNum = /[0-9]/;
    const patternChar = /[a-zA-Z]/;
    const patternSpc = /[~!@#$%^&*()_+|<>?:{}]/;
    return patternNum.test(password) && patternChar.test(password) && patternSpc.test(password);
  },

  validatePhoneNumber(phoneNumber) {
    const regPhone = /^01([0|1|6|7|8|9])-?([0-9]{3,4})-?([0-9]{4})$/;
    return regPhone.test(phoneNumber);
  },

  getShortDate(date) {
    return moment(date).format('yyyy-MM-DD');
  },

  getShortDateTime(date) {
    return moment(date).format('yyyy-MM-DD HH:mm');
  },

  //for program status
  getProgramStatusColor(status) {
    let color = '';
    switch (status) {
      case 0:
        color = 'badge-soft-secondary';
        break;
      case 1:
        color = 'badge-soft-primary';
        break;
      case 2:
        color = 'badge-soft-success';
        break;
      case 3:
        color = 'badge-soft-warning';
        break;
      default:
        color = 'badge-soft-dark';
    }
    return color;
  },
  getProgramStatusLabel(status) {
    let name = '';
    switch (status) {
      case 0:
        name = '준비중';
        break;
      case 1:
        name = '진행중';
        break;
      case 2:
        name = '마감';
        break;
      case 3:
        name = '종료';
        break;
      default:
        name = '알 수 없음';
    }
    return name;
  },
  getUserStatusColor(status) {
    let color = '';
    switch (status) {
      case 0:
        color = 'primary';
        break;
      case 1:
        color = 'success';
        break;
      case 9:
        color = 'danger';
        break;
      default:
        color = 'warning';
    }
    return color;
  },
  getUserStatusLabel(status) {
    let name = '';
    switch (status) {
      case 0:
        name = '가입중';
        break;
      case 1:
        name = '정상';
        break;
      case 9:
        name = '탈퇴';
        break;
      default:
        name = '모름';
    }
    return name;
  },
    //for program status
    getReservationStatusColor(status) {
      let color = '';
      switch (status) {
        case 1:
          color = 'badge-soft-secondary';
          break;
        case 2:
          color = 'badge-soft-primary';
          break;
        case 3:
          color = 'badge-soft-success';
          break;
        case 4:
          color = 'badge-soft-warning';
          break;
        default:
          color = 'badge-soft-dark';
      }
      return color;
    },
    getReservationStatusLabel(status) {
      let name = '';
      switch (status) {
        case 1:
          name = '입금대기중';
          break;
        case 2:
          name = '결제완료';
          break;
        case 3:
          name = '취소요청';
          break;
        case 4:
          name = '취소완료';
          break;
        default:
          name = '알 수 없음';
      }
      return name;
    },
    getPaymentMethodLabel(type) {
      let name = '';
      switch (type) {
        case 1:
          name = '신용카드';
          break;
        case 2:
          name = '무통장입금';
          break;
        case 3:
          name = '카카오페이';
          break;
        case 4:
          name = '네이버페이';
          break;
        default:
          name = '모름';
      }
      return name;
    },
  inputNumberOnly: function(evt) {
    evt = (evt) ? evt : window.event;
    var charCode = (evt.which) ? evt.which : evt.keyCode;
    if ((charCode > 31 && (charCode < 48 || charCode > 57)) && charCode !== 46) {
      evt.preventDefault();;
    } else {
      return true;
    }
  },
  maxLength(str, max) {
    if (str.length >= max) {
      return str(0, 14) + "...";
    } else {
      return str;
    }
  },

   getUUID() {
    function _s4() {
      return ((1 + Math.random()) * 0x10000 | 0).toString(16).substring(1);
    }
    return _s4() + _s4() + '-' + _s4() + '-' + _s4() + '-' + _s4() + '-' + _s4() + _s4() + _s4();
  },
};
