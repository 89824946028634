<template>
  <main class="main" id="top">
    <div class="container" data-layout="container">
      <Navigation/>
      <div class="content">
        <div class="row g-5 mb-2">
          <div class="col-12">
            <div class="row">
              <div class="col-lg-12 text-left">
                <h2 class="section-heading">이용약관</h2>
                <br/><br/>
                <p>제1장 총 칙</p>
                <p><br/></p>
                <p>제1조 （이용약관）</p>
                <p>
                  1. 주식회사문화상상연구소(이하, ‘당사’라고 함)가 실시하는 놀토앱(이하, ‘본 앱’이라고 함)을 이용할 때는 이 놀토앱의 이용규약(이하, ‘본 규약’이라고 함)에 동의한 후에 본
                  앱을 다운로드 받고, 본 규약을 준수해야 합니다. 본 규약은 본 앱을
                  이용하는 분들(이하, ‘이용자’라고 함)이 본 앱을 이용할 때 적용됩니다. 만약 동의하실 수 없다면 대단히 죄송하지만 이용을 삼가 주십시오.
                </p>
                <p><br/></p>
                <p>2. 본 규약 및 당사가 본 앱에 관해 본 앱상에 게시하거나 직접 이용자에게 개별적으로 연락하는 알림 등은 모두 본 규약과 통합된 것으로서 이용자는 이를 준수할 의무를
                  지닙니다.</p>
                <p><br/></p>
                <p>제2조 （본 규약의 변경）</p>
                <p>1. 당사는 스스로 필요하다고 판단될 경우, 이용자의 동의를 얻지 않고 수시로 본 규약을 추가, 변경 및 삭제(이하, 총칭하여 ‘변경 등’이라고 함)할 수 있습니다.</p>
                <p>2. 본 규약이 변경된 후에 이용자가 본 앱을 이용한 경우, 변경된 이후의 본 규약에 동의하신 것으로 간주합니다.</p>
                <p><br/></p>
                <p>제2장 본 앱 및 이용자 등</p>
                <p><br/></p>
                <p>제3조 （정의）</p>
                <p>1. 본 규약에서 ‘본 앱 콘텐츠’란 이용자 자신이 소유한 스마트폰 등의 휴대 정보 단말기에 당사가 제공하는 본 앱을 설치했을 때, 당사가 해당 휴대 정보 단말기에 제공하는 각종
                  정보(이하, ‘본 앱 관련 정보’라고 함)를 말합니다.</p>
                <p>2. 이용자 전송 정보란 이용자가 본 앱상에서 당사에 전송하는 각종 정보 및 본 앱을 통해 이용자로부터 제공된 정보를 말합니다.</p>
                <p><br/></p>
                <p>제4조 (본 앱의 다운로드)</p>
                <p>1. 본 앱의 이용을 희망하는 개인은 본 규약에 동의한 후, 본 앱을 자신이 소유한 휴대 정보 단말기에 본 앱 다운로드하기를 실행해야 합니다.</p>
                <p><br/></p>
                <p>제5조 （본 앱 이용에 관한 여러 조건）</p>
                <p>1. 이용자는 본 규약의 각 조항에 따라 본 앱을 위해 본 앱을 무상으로 사용할 독점적인 권리를 취득합니다.</p>
                <p>2. 이용자는 본 앱을 제삼자에게 사용 허락, 대여 또는 리스할 수 없습니다.</p>
                <p>3. 이용자는 본 앱의 전부 또는 일부를 복제, 변경 등을 할 수 없습니다.</p>
                <p>4. 이용자는 본 앱의 리버스 엔지니어링, 역컴파일 또는 역어셈블을 할 수 없습니다.</p>
                <p>5. 본 앱의 등록, 이용, 변경 및 이용 정지로 발생하는 통신비에 대해서는 이용자가 지불하며, 당사는 일체 부담하지 않습니다.</p>
                <p>6. 본 앱에 게재되어 있는 캠페인 정보는 캠페인을 기획하고 있는 당사가 제공 또는 당사가 승인한 제삼자 기업의 캠페인 기획을 제공하고 있습니다.</p>
                <p>제 6조 (고객의 아이디 및 비밀번호 관리)</p>
                <p>1. “고객”의 아이디와 비밀번호(이하 “식별수단”이하 함)에 관한 관리책임은 “고객”에게 있으며, 이를 제3자가 이용하도록 하여서는 안됩니다.</p>
                <p>2. “고객”은 “식별수단”이 도용되어 제3자가 사용하고 있음을 인지한 경우에는 이를 즉시 “회사”에 통지하고 “회사”의 안내에 따라야 합니다.</p>
                <p>3. 전 항의 규정에도 불구하고 해당 “고객”이 “회사”에 그 “식별수단”의 도용 사실을 통지하지 않거나, 통지한 후 “회사”의 안내에 따르지 않아 “고객”에게 발생한 불이익에
                  대하여 “회사”는 어떠한 책임도 부담하지 않습니다.</p>
                <p><br/></p>
                <p><br/></p>
                <p>제7조 （본 앱의 콘텐츠 변경）</p>
                <p>1. 당사는 본 앱 콘텐츠의 내용 등을 변경할 수 있습니다. 또한, 변경 효력은 당사가 별도로 규정한 경우를 제외하고, 당사가 본 앱상에서 해당하는 본 앱 콘텐츠를 변경한 시점부터
                  발생합니다.</p>
                <p>2. 전항에 의거하여 본 앱 콘텐츠의 내용 등이 변경된 경우, 이로 인해 발생한 이용자의 손해에 관해 당사는 일절 책임을 지지 않습니다.</p>
                <p><br/></p>
                <p>제8조 （본 앱의 이용 중단）</p>
                <p>1. 당사는 이하의 각호 중 어느 하나에 해당하는 사유가 발생한 것으로 판단한 경우, 이용자에게 사전 통보 없이, 본 앱의 전부 또는 일부 이용을 중단, 정지, 폐지 등(이하,
                  총칭하여 ‘중단 등’이라고 함)을 할 수 있습니다.</p>
                <p>(1) 본 앱용 설비 등의 유지보수를 정기적 또는 긴급으로 실시할 경우</p>
                <p>(2) 화재, 정전, 기타 불의의 사고 등으로 본 앱을 이용할 수 없게 된 경우</p>
                <p>(3) 지진, 분화, 홍수, 해일 등의 자연재해로 본 앱을 이용할 수 없게 된 경우</p>
                <p>(4) 전쟁, 동란, 폭동, 내전, 노동 쟁의, 통상 금지, 파업, 물자 및 수송 시설의 확보 불능 또는 정부 당국의 개입 등으로 본 앱을 이용할 수 없게 된 경우</p>
                <p>(5) 기타 운용상 또는 기술상 본 앱의 중단 등이 필요하다고 당사가 판단한 경우</p>
                <p>2. 여러 사정으로 본 앱을 지속적으로 제공하기 어려울 경우, 당사는 당사의 판단에 의해 이용자의 허가를 얻지 않고, 본 앱의 전부 또는 일부의 이용을 폐지할 수 있습니다.</p>
                <p>3. 당사는 전 2항 중 어느 하나 또는 그것과 유사한 사유로 인해 본 앱의 이용 중단 등으로 이용자 또는 제삼자가 입은 손해에 대해 일절 책임을 지지 않습니다.</p>
                <p><br/></p>
                <p>제9조 （본 앱의 이용 정지）</p>
                <p>본 앱의 이용 정지를 원하는 이용자는 본 앱 내의 메뉴 또는 소정의 방법으로 본 앱을 제거함으로써, 이용을 정지할 수 있습니다.</p>
                <p><br/></p>
                <p>제3장 이용자의 책임과 의무</p>
                <p><br/></p>
                <p>제10조 （자기책임의 원칙）</p>
                <p>1. 이용자는 본 규약을 준수하고 타인을 존중하며 법률과 도덕, 예의를 지키고 자신의 책임으로 본 앱을 이용합니다.</p>
                <p>
                  2. 이용자는 본 앱의 이용과 관련하여 당사와 본 앱에 게재되는 점포, 또는 그 이외의 제삼자에게 손해를 입힌 경우(이용자가 본 규약상의 의무를 준수하지 않아서 당사나 게재 점포 또는
                  제삼자가 손해를 본 경우를 포함), 자신이 책임지고 비용을
                  부담함으로써 그에 따른 모든 손해를 배상(소송 비용 및 변호사 비용 포함)합니다.
                </p>
                <p><br/></p>
                <p>제11조 （금지 사항）</p>
                <p>1. 이용자는 전조에서 규정한 행위 외에 아래의 각호 중 어느 하나에 해당하는 행위를 해서는 안 됩니다.</p>
                <p>
                  (1)본 앱용 설비(당사 또는 당사가 지정한 본 앱을 제공하기 위해 준비하는 통신 설비, 통신 회선, 전자계산기, 기타 기기 및 소프트웨어를 포함)에 부정하게 접근하거나 이용 또는
                  운영에 지장을 주는 행위(이용의 통상 범위를 넘어서 서버에 부담을 주는
                  행위 포함)
                </p>
                <p>(2)휴대 정보 단말기를 부정하게 사용하거나 제삼자에게 부정한 사용을 유발시키는 행위</p>
                <p>(3)본 앱에 컴퓨터 바이러스 및 기타 유해한 컴퓨터 프로그램이 포함된 정보를 전송, 글쓰기 또는 게재하는 행위</p>
                <p>(4)다른 이용자 행세를 하여 본 앱을 이용하는 행위</p>
                <p>(5)본 앱을 부정하게 변경하는 행위</p>
                <p>(6)그 밖에 당사가 부적절, 부적당하다고 판단하는 행위</p>
                <p><br/></p>
                <p>제4장 지식재산권</p>
                <p><br/></p>
                <p>제12조 （지식재산권）</p>
                <p>
                  1. 이용자는 본 앱상에서 당사가 제공 또는 게재하는 정보 등(본 앱 관련 정보를 포함)에 대해서는 해당 정보에 관한 저작권, 특허권, 실용신안권, 상표권 및 의장권, 기타 모든
                  지식재산권(그것들의 권리를 취득하거나 그것들의 권리에 대한 등록 등을
                  출원하는 권리를 포함)이 당사 또는 제삼자에게 귀속되는 것을 납득하고, 이용자는 당사 및 당사에 대해 사용을 허락한 해당 제삼자의 허락을 얻었을 경우 또는 저작권법 제30조에 규정된
                  사적 사용의 범위에서 사용하는 경우를 제외하고, 해당 정보를 직접
                  사용 또는 제삼자에게 개시 또는 사용하게 할 수 없습니다.
                </p>
                <p>
                  2. 이용자가 본 앱상에서 제공한 이용자 전송 정보의 저작권 및 기타 모든 지식재산권은 이용자에게 귀속됩니다. 단, 이용자는 당사 및 당사가 지정한 제삼자에게 해당 이용자 전송 정보를
                  일본 국내외에서 무기한 및 무상으로 비독점적인 사용
                  권리(복제권, 자동공중송신권, 상영권, 배포권, 양도권, 대여권, 번역권, 번안권, 유상 또는 무상에 관계없이 물품으로 제작하여 제삼자에게 판매하는 권리가 포함되지만, 이에 한정되지
                  않음)를 허락(재허락권을 부여하는 권리를 포함)한 것으로
                  간주합니다. 이용자는 당사 및 당사가 지정한 제삼자에게 저작자 인격권을 일절 행사하지 않습니다. 또한, 이용자는 본 항에 의해 이용자에게 귀속되는 지식재산권을 제삼자에게 양도할 경우,
                  해당 지식재산권을 승계받는 제삼자에게 이용자가 책임지고 본
                  항의 규정을 승낙하게 합니다.
                </p>
                <p><br/></p>
                <p>제13조 （본 앱에 관한 권리）</p>
                <p>1. 본 앱에 관한 저작권 등의 지식재산권은 당사에 귀속되며, 본 앱은 일본의 저작권법, 기타 관련하여 적용되는 법률 등으로 보호받고 있습니다. 따라서 이용자는 본 앱을 다른
                  저작물과 동일하게 취급해야 합니다.</p>
                <p>2. 본 앱상에 표시되는 상표, 로고 및 서비스 마크 등(이하, 총칭하여 ‘상표 등’이라고 함)은 당사의 등록 또는 미등록 상표입니다. 당사는 본 규약에 의해 이용자 또는 기타
                  제삼자에게 어떠한 상표 등을 양도 또는 사용을 허락하지 않습니다.</p>
                <p><br/></p>
                <p>제5장 운영</p>
                <p><br/></p>
                <p>제14조 （당사의 권리）</p>
                <p>1. 당사는 본 앱상에 당사가 지정하는 제삼자가 취급하는 광고 등을 자유롭게 게재할 수 있습니다.</p>
                <p>2. 당사는 본 앱상에서 이용자로부터 정보를 수집(이용자 전송 정보 등을 포함하지만, 이에 한정되지 않음)할 수 있습니다. 당사는 수집한 정보에 대해서는 다음 조에 규정된 보안
                  정책에 따라 엄중하게 관리합니다.</p>
                <p><br/></p>
                <p>제15조 （보안 정책）</p>
                <p>1. 당사는 본 앱의 시스템 정보 기반(이하, ‘본 시스템’이라고 함)의 운영에서 다음 사항을 준수합니다.</p>
                <p>(1)본 시스템에 대한 접속 및 조작은 당사 내에서 규정된 담당자 및 시스템 관리자로 제한한다.</p>
                <p>(2)본 시스템의 기능이 정상적으로 작동하도록 바이러스나 외부 침입에 대해 통상적으로 입수할 수 있는 적절한 보안 시스템을 도입 및 운용하여 대응한다.</p>
                <p>(3)본 시스템의 업무 운영을 외부 사업자에게 위탁할 경우에도 전 2호를 철저히 준수한다.</p>
                <p>2. 당사의 개인정보 처리 전반에 관한 것은 당사 홈페이지에 있는 개인정보 보호방침을 따릅니다.</p>
                <p><br/></p>
                <p>제16조 （면책 및 손해 배상）</p>
                <p>1. 당사는 본 앱의 이용과 관련해 발생한 이용자의 손실 및 손해에 대해 당사에 고의 또는 중대 과실이 있는 경우를 제외하고, 일절 책임을 지지 않습니다.</p>
                <p>2. 당사는 본 앱상에서 당사 이외의 제삼자가 운영하는 웹사이트로 링크가 걸려 있는 경우, 이용자가 해당 링크의 웹사이트를 이용(과금, 물품 구입 등을 포함하지만, 이에 한정되지
                  않음)하면서 발생한 손해에 대해 일절 책임을 지지 않습니다.</p>
                <p>
                  3. 당사는 본 앱을 제공하는 기기의 고장, 트러블, 정전 등 또는 통신 회선의 이상 등 당사가 예측하지 못한 사유 또는 시스템 장애 등으로 인한 이용자의 본 앱 관련 정보 및 이용자
                  전송 정보가 소실된 경우, 이로 인한 손해에 대해 일절 책임을 지지
                  않습니다.
                </p>
                <p>4. 당사는 이용자가 본 규약을 위반 또는 본 앱의 적절한 이용을 벗어난 행위로 발생한 사회적, 정신적, 육체적, 금전적 손해에 대해 일절 책임을 지지 않습니다.</p>
                <p>
                  5. 당사는 이용자의 개인 인증을 거친 본 앱의 이용 또는 그에 따른 모든 행위는 해당 이용 및 행위가 해당 이용자 본인에 의한 것인지의 여부와 관계없이 해당 이용자로 인한 이용 및
                  행위로 간주하고, 해당 이용 및 행위의 결과로 발생한 손해에 대해
                  일절 책임을 지지 않습니다.
                </p>
                <p>6. 당사는 본 앱의 이용 중단 등에 따라 이용자가 부담한 모든 비용(통신비 등을 포함하지만, 이에 한정되지 않음)에 대해 이용자에 대한 배상 책임을 지지 않습니다.</p>
                <p>7. 당사는 본 앱의 이용 방법 등에서 이용자의 불찰이 있었을 경우, 그 결과로 이용자에게 발생한 불이익 또는 손해에 대해 일절 책임을 지지 않습니다.</p>
                <p>8. 본 앱은 당사가 이용자에 대해 본 앱을 실행하는 시점에서 당사에게 있어 제공 가능한 내용이며, 본 앱에 대해 하자가 없는 것임을 보증하지는 않습니다.</p>
                <p>9. 당사는 이용자가 본 앱을 이용하면서 얻은 본 앱 관련된 정보를 포함한 정보 등에 대해 그 내용의 진위, 유익성, 완전성, 적법성, 타당성, 신뢰성, 유용성, 정확성 등에 대해
                  일절 보증하지 않습니다.</p>
                <p>
                  10. 당사는 이용자가 본 앱용 설비에 축적된 데이터 등(이용자 제공 정보를 포함)이 소실(이용자 본인에 의한 삭제도 포함)되거나 제삼자에 의해 변경되었더라도 해당 데이터를 복구할
                  의무를 포함하여, 일절 책임을 지지 않습니다. 또한, 다른 이용자가
                  다운로드한 이용자 제공 정보에 대해 발생한 행위(변경, 열람 등을 포함)에 대해 당사는 일절 책임을 지지 않습니다.
                </p>
                <p>11. 제1항부터 제10항의 규정에도 불구하고 적용 법령 또는 법원의 확정판결 등으로 본 규약에서 규정한 당사의 면책이 인정되지 않을 경우, 당사는 이용자가 직접적이고 현실적으로
                  입은 통상적인 손해에 한하여, 그에 따른 배상 책임을 집니다.</p>
                <p><br/></p>
                <p>제17조 （준거법 및 전속적 합의 관할 재판소）</p>
                <p>1. 본 규약은 한국법에 따라 해석됩니다.</p>
                <p>2. “서비스” 이용에 관해 발생한 분쟁이 원만하게 해결되지 아니한 경우 관련 소송의 관할은 “회사” 본점 소재지를 관할하는 법원으로 합니다.</p>
                <p><br/></p>
                <p>제 18조 (기타)</p>
                <p>본 약관에서 정하지 않은 사항 및 내용 해석상의 이견이 있을 경우에는 일반상관습에 따르기로 합니다.</p>
                <p><br/></p>
                <p>부칙</p>
                <p>본 약관은 2021년 08월 08일부터 시행합니다.</p>
                <div><br/></div>

              </div>
            </div>
            <div class="row">
              <div class="col-lg-12 text-center">
                <a href="/" class="btn btn-primary btn-xl page-scroll">메인으로</a>
              </div>
            </div>
          </div>
        </div>
      </div>
      <FooterContent/>
    </div>
  </main>
</template>

<script>
import Navigation from '@/components/common/Navigation.vue';
import FooterContent from '@/components/common/FooterContent.vue';

export default {
  components: {
    Navigation,
    FooterContent,
  },
};
</script>
