<template>
  <main class="main" id="top">
    <div class="container" data-layout="container">
      <Navigation ref="navigation" />
      <!-- start content -->
      <div class="content">
        <nav class="mt-3 mb-3" aria-label="breadcrumb" v-show="type === 'edit'">
          <ol class="breadcrumb">
            <li class="breadcrumb-item">Home</li>
            <li class="breadcrumb-item active" aria-current="page">회원정보</li>
          </ol>
        </nav>
        <!-- start collection editor -->
        <div class="row justify-content-center">
          <div class="col-xl-7 col-lg-9">
            <div class="card mb-3">
              <div class="card-header">
                <div class="row align-items-center">
                  <h5 class="mb-0">{{type === 'edit' ? '사용자 정보 수정' : '회원가입'}} </h5>
                </div>
              </div>
              <div class="card-body bg-light">
                <form class="row g-3">
                  <div class="col-lg-6">
                    <div class="avatar avatar-4xl rounded-circle">
                      <div class="h-100 w-100 rounded-circle overflow-hidden position-relative">

                        <img v-if="images == null" class="rounded-circle" ref="profileImage" src="/img/default-profile.png" alt="" style="object-fit: contain" />
                        <img v-else class="rounded-circle" ref="profileImage" :src="images" alt="" />

                        <input class="d-none" id="imageFile" ref="imageFile" type="file" accept="image/*" @change="selectImage()" />
                        <label class="mb-0 overlay-icon d-flex flex-center" for="imageFile">
                          <span class="bg-holder overlay overlay-0"></span>
                          <span class="z-index-1 text-white dark__text-white text-center fs--1">
                            <span class="fas fa-camera"></span>
                          </span>
                        </label>
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-6">
                    <label class="form-label" for="user-name">이름</label>
                    <input class="form-control" id="user-name" type="text" ref="name" placeholder="이름" v-model="user.name" />
                  </div>
                  <div class="col-lg-6">
                    <label class="form-label" for="user-email">이메일</label>
                    <input class="form-control" id="user-email" type="email" ref="email" placeholder="이메일" v-model="user.email" />
                  </div>
                  <div class="col-lg-6">
                    <label class="form-label" for="user-contact">연락처</label>
                    <input class="form-control" id="user-contact" type="tel" ref="contact" placeholder="연락처" v-model="user.contact" />
                  </div>
                  <div class="col-lg-6">
                    <label class="form-label" for="user-birthYear">출생년도</label>
                    <datepicker
                      input-class="form-control border-0 bg-white text-center yearpick"
                      v-model="userBirthYear"
                      :language="ko"
                      :format="yearPickFormat"
                      id="user-birthYear"
                      minimum-view="year"
                      width="150"
                      @closed="changeBirthDay"
                    ></datepicker>
                  </div>
                  <div class="col-lg-6">
                    <label class="form-label" for="user-gender">성별</label>
                    <div class="row" id="user-gender">
                      <div class="col-md-6 mb-3 mb-md-0">
                        <div class="form-check mb-0 custom-radio radio-select">
                          <input :checked="user.gender === 'male'" class="form-check-input" v-model="user.gender" id="maleRadio" type="radio" value="male" @change="user.gender = 'male'" />
                          <label class="form-check-label" for="maleRadio">남성</label>
                        </div>
                      </div>
                      <div class="col-md-6">
                        <div class="position-relative">
                          <div class="form-check mb-0 custom-radio radio-select">
                            <input :checked="user.gender === 'female'" class="form-check-input" v-model="user.gender" id="femaleRadio" type="radio" value="female" @change="user.gender = 'female'" />
                            <label class="form-check-label" for="femaleRadio">여성</label>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div class="col-lg-6">
                    <div class="mb-2">
                      <label class="form-label" for="children">자녀정보</label>
                    </div>
                    <div class="mb-2" v-for="(value, index) in children" :key="index">
                      <ChildEdit :child="{ index: index, value: value }" @changeChild="changeChildValue" @deleteChild="deleteChild" />
                    </div>
                    <div class="mb-2">
                      <a class="btn btn-falcon-default btn-sm" @click="newChild()"><span class="fas fa-plus-circle me-1"></span>자녀 추가</a>
                    </div>
                  </div>
                  <div class="col-lg-6" v-show="!user.agreeTime">
                    <label class="form-label" for="user-recommender">추천인(연락처)</label>
                    <input class="form-control" id="user-recommender" type="tel" ref="recommender" placeholder="추천인(연락처)" v-model="recommender" />
                  </div>
                </form>
              </div>
              <div class="card-footer border-top text-end">
                <a class="btn btn-falcon-default btn-sm ms-2" href="/"> 취소 </a>
                <a class="btn btn-falcon-default btn-sm ms-2" @click="updateUser"> {{type === 'edit' ? '수정' : '회원가입' }} </a>
              </div>
            </div>
          </div>
        </div>
        <!-- end collection editor -->
        <!-- end content -->
      </div>
      <FooterContent />
    </div>
  </main>
</template>
<script>
// @ is an alias to /src
import Navigation from '@/components/common/Navigation.vue';
import FooterContent from '@/components/common/FooterContent.vue';
import ModalDialog from '@/components/common/ModalDialog.vue';
import ChildEdit from '@/components/auth/ChildEdit.vue';
import { ko } from 'vuejs-datepicker/dist/locale';
import Datepicker from 'vuejs-datepicker';
import moment from 'moment';
export default {
  name: 'ProfileEdit',
  components: {
    Navigation,
    FooterContent,
    ModalDialog,
    Datepicker,
    ChildEdit,
  },
  data() {
    return {
      user: {
        email: "",
        name: "",
        source: "",
        sourceId: "",
        gender: "",
        birthYear: 0,
        interest: "",
        deviceId: "",
        contact: "",
        region: "",
        profileImageUrl: null,
        resourceId: null
      },
      children: [],
      agreePrivacy: [],
      userBirthYear: 1980,
      yearPickFormat: 'yyyy',
      recommender: '',
      ko: ko,
      rand: 1,
      images: null,
      type: null
    };
  },
  created() {
    this.type = this.$route.params.type;//
    // console.log(this.type);
    if(this.$func.getUser() != null){
      this.user = this.$func.getUser();
    }
    // console.log(this.user);
    if(this.user !== null){
      this.images = process.env.VUE_APP_BASE_URL +  this.user.profileImagePath;
      // console.log(this.image);
      this.children = this.user.interest ? JSON.parse(this.user.interest) : [];
      this.userBirthYear = this.user.birthYear == null ? '1989' : this.user.birthYear;
      if (this.user.gender == null) {
        this.user.gender = 'female';
      }
    }
  },

  methods: {
    selectImage() {
      console.debug('selectImage:', this.$refs.imageFile.files[0]);

      if (!this.$refs.imageFile.files[0].type.startsWith('image/')) {
        this.$toast.warning('이미지 파일이 아닙니다.');
        this.$refs.imageFile.value = '';
        return;
      }

      if (this.$refs.imageFile.files[0].size > this.$config.maxImageSize) {
        this.$toast.warning(`(파일사이즈: ${this.$func.getfileSize(this.$refs.imageFile.files[0].size)}) 파일크기는 1MB 이하여야 합니다.`);
        this.$refs.imageFile.value = '';
        return;
      }
      const file = this.$refs.imageFile.files[0];
      if (file) {
        const reader = new FileReader();
        reader.onload = (e) => {
          this.images = e.target.result;
        };
        reader.readAsDataURL(file);
      }

      const formData = new FormData();
      formData.append('profile', this.$refs.imageFile.files[0]);
      if(this.type === 'edit'){
        this.$http
            .patch('/user/image', formData, {
              headers: {
                'Content-type': 'multipart/form-data',
              },
            })
            .then((response) => {
              //console.log('response:', response);
              this.user.resourceId = response.data.resourceId;
              this.user.profileImageUrl = response.data.profileImagePath;

            })
            .catch((error) => {
              console.log(error);
              this.$toast.error('프로필 사진을 수정하는 중 오류가 발생하였습니다.');
            });
      } else {
        this.$http
            .post('/user/signup/image', formData, {
              headers: {
                'Content-type': 'multipart/form-data',
              },
            })
            .then((response) => {
              //console.log('response:', response);
              this.user.resourceId = response.data.resourceId;
              this.user.profileImageUrl = response.data.profileImagePath;

            })
            .catch((error) => {
              console.log(error);
              this.$toast.error('프로필 사진을 수정하는 중 오류가 발생하였습니다.');
            });
      }

    },

    changeBirthDay() {
      this.userBirthYear = moment(this.userBirthYear).format('YYYY');
      //console.log('user.birthYear:', this.user.birthYear);
    },
    newChild() {
      this.children.push('2010');
    },

    changeChildValue(child) {
      //console.log('changeChildValue - child:', child);
      this.children[child.index] = child.value;
      //console.log('children:', this.children);
    },

    deleteChild(child) {
      //console.log('deleteChild - child:', child);
      this.children.splice(child.index, 1);
      //console.log('children:', this.children);
    },

    updateUser() {
      console.debug('updateUser');


      if (this.user.name.length < 2 || this.user.name.length > 5) {
        console.debug('name.length', this.user.name.length);
        this.$toast.warning('이름은 2~5자로 입력하세요.');
        this.$refs.name.focus();
        return;
      }

      if (this.user.email && !this.$func.validateEmail(this.user.email)) {
        this.$toast.warning('이메일이 올바르지 않습니다.');
        this.$refs.email.focus();
        return;
      }

      if (!this.user.contact) {
        this.$toast.warning('연락처를 입력해주세요.');
        this.$refs.contact.focus();
        return;
      }

      if (this.user.contact && !this.$func.validatePhoneNumber(this.user.contact)) {
        this.$toast.warning('연락처가 올바르지 않습니다.');
        this.$refs.contact.focus();
        return;
      }
      // 자녀 추가
      this.user.interest = JSON.stringify(this.children);


      if(this.type === 'edit'){

        if(this.user.resourceId === null){
          delete this.user.resourceId
        }

        if(this.user.profileImageUrl === null){
          delete this.user.profileImageUrl
        }

        this.$http
            .patch('/user', this.user)
            .then((response) => {
              //console.log('response:', response);
              this.getUserInfo();
            })



      } else if (this.type === 'signup'){


        if (!this.user.email) {
          this.$toast.warning('이메일을 입력해주세요.');
          this.$refs.email.focus();
          return;
        }



        if(this.user.birthYear === 0){
          this.$toast.warning('출생년도를 입력해주세요.');
          return;
        }

        if (!this.user.profileImageUrl && !this.user.resourceId) {
          this.$toast.warning('프로필 이미지를 올려주세요.');
          this.$refs.contact.focus();
          return;
        }



        if (!this.user.agreeTime) {
          this.user.recommender = this.recommender;
        }

        this.user.sourceId = `${this.user.sourceId}`;
        this.user.birthYear = parseInt(this.userBirthYear, 10);
        this.user.profileImageUrl = process.env.VUE_APP_BASE_URL + this.user.profileImageUrl;

        this.$http
            .post('/user/signup', this.user)
            .then((response) => {
              //console.log('response:', response);
              this.$toast.success('회원가입이 완료되었습니다.');
              this.$router.push('/');

            })
            .catch((error) => {
              console.log(error);
              this.$toast.error('회원가입 중 오류가 발생하였습니다.');
            });
      }

    },

    async getUserInfo()  {
      //console.log('getUserInfo');

      try{
        let userDataRes1 = await this.$http.get(`/user`); // 일반적인 이용자 정보들
        const userDataRes2 = await this.$http.get(`/my-page`); // 프로필 이미지 주소
        userDataRes1.data.profileImagePath = userDataRes2.data.profileImagePath ?? null;

        localStorage.setItem('userData', JSON.stringify(userDataRes1.data));
        this.$toast.success('회원정보를 수정하였습니다.');
        this.$router.push('/');
      }catch (error) {
        console.log(error);
        this.$toast.error('회원정보를 가져오지 못했습니다.');
      }

    },
  },
};
</script>
