<template></template>
<script>
export default {
  name: 'welcome',
  components: {},
  created() {
    //console.log('Welcome - created()');
    if (this.$func.isLoggedIn()) {
      //console.log('logged in');
      const userData = this.$func.getUser();

      if (userData.role === 'ROLE_ADMIN' || userData.role === 'ROLE_MANAGER') {
        //console.log('admin or manager');
        this.$router.push('/admin/programs');
      } else {
        if (process.env.NODE_ENV == 'remote') {
            this.$router.push('/login');
        } else {
          window.location.href = '/';
        }
      }
    } else {
      //console.log('logged out');
      localStorage.removeItem('userData');
      if (process.env.NODE_ENV == 'remote') {
        this.$router.push('/login');
      } else {
        window.location.href = '/';
      }
    }
  },
};
</script>
