<template>
  <div
    class="modal fade"
    id="modalInquiry"
    tabindex="-1"
    role="dialog"
    aria-hidden="true"
  >
    <div
      class="modal-dialog modal-dialog-centered"
      role="document"
      style="max-width: 640px"
    >
      <div class="modal-content position-relative">
        <div class="position-absolute top-0 end-0 mt-2 me-2 z-index-1">
          <button
            class="btn-close btn btn-sm btn-circle d-flex flex-center transition-base"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-header">
          <h5 class="modal-title">{{ modalTitle }}</h5>
        </div>
        <div class="modal-body">
          <label class="form-label">질문 : </label>
          <p class="mb-1">{{inquiry.question}}</p>
          <label class="form-label" for="inputAnswer">답변 : </label>
          <textarea class="form-control" ref="inputAnswer" id="inputAnswer" v-model="answerContents" rows="3"></textarea>
        </div>
        <div class="modal-footer">
          <button
            class="btn btn-secondary rounded-pill"
            type="button"
            data-bs-dismiss="modal"
          >
            취소
          </button>
          <button
            class="btn btn-primary rounded-pill"
            type="button"
            data-bs-dismiss="modal"
            :disabled="!isWritable"
            @click="onClickOk"
          >
            저장
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Editor from '@tinymce/tinymce-vue'
export default {
  name: 'ProductInquiryDialog',
  components: {
    editor: Editor,
  },
  props: {
    inquiry: {
      type: Object,
      required: true,
      default() {
        return {id:-1, programProductId:-1,memo:'',userUid:''}
      },
    },
  },
  created() {
    console.info('.created');
  },
  data() {
    return {
      answerContents:''
    }
  },
  computed: {
    modalTitle() {
      return this.inquiry.answer ? '답변수정' : '답변작성'
    },
    isWritable() {
      return this.answerContents && this.answerContents.length > 0;
    },
  },
  //모달은 시작과 동시에 생성되므로 Dialog가 뜰때 반드시 watch를 생성해야함
  watch: {
    inquiry: function(newVal, oldVal) {
      console.info('inquiry changed:', newVal)
      this.inquiry = newVal;
      this.answerContents = this.inquiry.answer
    },
  },
  methods: {
    onClickOk() {
      console.info('onClickOk')
      this.inquiry.answer = this.answerContents;
      this.$emit('saveAnswer', this.inquiry);
    },
  },
}
</script>
