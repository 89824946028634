<template>
  <main class="main" id="top">
    <div class="container-fluid" data-layout="container-fluid">
      <Navigation />
      <div class="content">
        <Header />
    <div class="row justify-content-center">
      <div class="col-lg-12">
        <!--start content-->
        <div class="card mb-3">
          <div class="card-header">
            <h5 class="mb-0" data-anchor="data-anchor" id="card-styles">프로그램 질의 응답</h5>
          </div>
          <div class="card-body bg-light border-top">
            <div class="row align-items-center">
              <div class="col d-flex align-items-center">
                <h5 class="card-title">{{ program.title }}</h5>
              </div>
              <div class="col-auto">
                <span :class="`badge rounded-pill d-block p-2 ${$func.getProgramStatusColor(parseInt(program.status))}`">
                  {{$func.getProgramStatusLabel(parseInt(program.status))}}</span>
                <small>{{ `시작일 : ${program.startDate}` }}</small>
              </div>
            </div>
          </div>
          <div class="card-footer border-top ">
            <a class="btn btn-falcon-default btn-sm ms-2" :href="`/admin/programs`"><span class="far fa-list-alt me-1"></span>프로그램 목록</a>
          </div>
        </div>
        <div class="card mb-3">
            <div class="card-header">
              <h5 class="mb-0">질의 목록</h5>
            </div>
          <div class="card-body pt-0">
              <div class="table-responsive scrollbar">
                <table class="table table-hover table-striped table-bordered fs--1 mb-0">
                  <thead>
                    <tr>
                      <th class="text-center" style="width:130px">사용자</th>
                      <th class="text-center" style="width:80px">공개여부</th>
                      <th class="text-center">질문</th>
                      <th class="text-center">답변</th>
                      <th class="text-center" style="width:120px">작성일자</th>
                      <th class="text-center" style="width:100px">답변</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr class="align-middle" v-for="inquiry in inquiryItems" :key="inquiry.id">
                      <td class="text-center">{{inquiry.userName}}</td>
                      <td class="text-center">{{inquiry.isPrivacy?'비공개':'공개'}}</td>
                      <td >{{inquiry.question}}</td>
                      <td >{{inquiry.answer}}</td>
                      <td class="text-center">{{$func.getShortDate(inquiry.createTime)}}</td>
                      <td class="text-center">
                        <a class="btn btn-sm btn-outline-primary rounded-pill"              
                        data-bs-toggle="modal" 
                        data-bs-target="#modalInquiry" 
                        href="#"
                        @click.prevent="setParams(inquiry)"><span style="font-size:smaller;">{{inquiry.answer ? '답변수정':'답변작성'}}</span>
                        </a>
                      </td>
                    </tr>
                  </tbody>
                </table>
              </div>
          </div>
        </div> 
      </div>
    </div>
      <ProgramInquiryDialog
        :inquiry="params"
        @saveAnswer="saveAnswer"
      ></ProgramInquiryDialog>
        <FooterContent />
      </div>
    </div>
  </main>

</template>
<script>
import Header from '@/components/admin/common/Header.vue';
import Navigation from '@/components/admin/common/Navigation.vue';
import FooterContent from '@/components/admin/common/FooterContent.vue';
import ProgramInquiryDialog from '@/components/admin/program/ProgramInquiryDialog.vue';
import moment from 'moment';
export default {
  name: 'ProgramInquiry',
  components: {
    ProgramInquiryDialog,
    moment,
    Header,
    Navigation,
    FooterContent,
  },
  data() {
    return {
      programId: -1,
      program: {},
      inquiryItems:[],
      params:{},
    };
  },
  created() {
    //console.log('created - programId : ' + this.$route.params.id);
    this.programId = this.$route.params.id;
    this.getProgramData();
  },
  methods: {
    setParams(params) {
      //console.log('setParams-params:', params);
      return this.params = params;
    },
    getProgramData() {
      //console.log('getProgramData');
      this.$http
        .get('/admin-api/get-program.nolto',{
          params: {
            programId: this.programId,
          }
        })
        .then((response) => {
          //console.log(response);
          if (response.data.error) {
            this.$toast.error(response.data.message);
            return;
          }
          this.program = response.data.program;
         this.getProgramInquiry();
        })
        .catch((error) => {
          console.log(error);
          
        });
    },
    getProgramInquiry() {
      //console.log('getProgramInquiry');
      this.$http
        .get('/admin-api/get-program-inquiry.nolto',{
          params: {
            programId: this.programId
          }
        })
        .then((response) => {
          //console.log(response);
          if (response.data.error) {
            this.$toast.error(response.data.message);
            return;
          }
          this.inquiryItems = response.data.inquiryItems;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    saveAnswer(inquiry) {
      //console.log('saveAnswer-inquiry:', inquiry);
      this.$http
        .post('/admin-api/save-inquiry-answer.nolto', inquiry)
        .then((response) => {
          //console.log(response);
          if (response.data.error) {
            this.$toast.error(response.data.message);
            return;
          }
          this.getProgramInquiry();
          this.$toast.success(response.data.message);
        })
        .catch((error) => {
          console.log(error);
          
        });
    },
  },
};
</script>
