<template>
  <!-- <div class="card" style="height: 100%"> -->
  <div class="card">
    <div class="card-img-top position-relative">
      <a class="d-block card-img-top image-zoom" :href="`/program/${program.id}`" >
        <img class="fit-cover" :src="imageUrl" style="width: 100%; height: auto" :title="this.program.title" />
        <!-- <img class="fit-cover" :src="imageUrl" style="width: 100%; height: 220px" :title="this.program.TITLE" /> -->
      </a>
      <div  class="position-absolute mt-2 ms-2 z-index-2 top-0 start-0 me-2">
        <span class="badge ms-2 mb-1" v-for="(target,index) in targetList" :key="index" style="text-align: start; white-space: normal;  background-color: rgba(0,0,0,0.4)">{{target}}</span>
      </div>
    </div>
    <div class="card-body d-flex flex-column justify-content-between">
      <div class="mb-2">
        <star-rating
        :border-width="1"
        border-color="#d8d8d8"
        :rounded-corners="true"
        :increment="1"
        :star-size="15"
        :read-only="true"
        :show-rating="false"
        :rating="this.program.rating"
        :star-points="[23,2, 14,17, 0,19, 10,34, 7,50, 23,43, 38,50, 36,34, 46,19, 31,17]">
        </star-rating>
      </div>
      <div class="mt-2 mb-2">
        <p class="fs--1 mb-1"><span class="fas fa-map-marker-alt me-2"></span>{{areaLabel}}</p>
        <p class="fs--1 mb-1"><span class="far fa-calendar-check me-2"></span>{{startDateLabel}}</p>
      </div>
      <div class="mt-2">
        <div class="row justify-content-between">
          <div class="col">
            <h4 class="fw-bold" :class="dday.color">{{programStatus}}</h4>
          </div>
          <div class="col-auto">
            <h4 class="fw-bold">{{program.price ? program.price.toLocaleString('ko-KR') : 0}}원</h4>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import moment from 'moment';
import StarRating from 'vue-star-rating';
export default {
  name: 'ProgramGridItem',
  components: {
    StarRating,
  },
  data() {
    return {

    }
  },
  props: {
    program: {
      type: Object,
      required: true,
      default: {
        id: -1,
        title: '오감체험 제목',
        price: 10000,
        keyword:[],
        area: '체험장소',
        startData:'1970-01-01',
        target: '',
        rating: 5,
        status: 0,
      },
    },
  },
  created() {
    // console.debug('created - program:', this.program);
  },
  computed: {
    imageUrl() {
      return process.env.VUE_APP_BASE_URL + this.program.imagePath;
    },
    programStatus(){
      const statuses = {
        0: "준비중",
        1: "진행중",
        2: "마감",
        3: "종료",
        4: "임시"
      };
      return statuses[this.program.status]
    },
    startDateLabel() {
      return moment(this.program.startDate).format('YYYY년 MM월 DD일 부터');
    },
    areaLabel() {
      if (this.program.area && this.program.area.length > 20) {
        return this.program.area.substring(0,20) + '...';
      } else {
        return this.program.area
      }
    },
    dday() {
      let label = '알 수 없음';
      let color = 'text-600';
      switch(this.program.status) {
        case 0:
        label = '준비중';
        color = 'text-danger';
        break;
        case 1: {
          const startDate = moment(this.program.startData);
          const nowDate = moment();
          const diff = parseInt(moment.duration(startDate.diff(nowDate)).asDays());

          if (diff > 0) {
            label = 'D-' + diff + '일';
          } else {
            label = '진행중';
          }
        }
        color = 'text-danger';
        break;
        case 2:
        label = '마감';
        color = 'text-primary';
        break;
        case 3:
        label = '종료';
        color = 'text-600';
        }
        return {label:label, color:color};
    },
    targetList() {
      if(this.program.target === '' || this.program.target == null){
        return [];
      } else {
        return this.program.target.split(',');
      }

    }
  },
  methods: {

  },
};
</script>
