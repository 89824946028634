<template>
  <main class="main" id="top">
    <div class="container" data-layout="container">
      <Navigation />
      <nav class="mt-3 mb-3" aria-label="breadcrumb">
        <ol class="breadcrumb">
          <li class="breadcrumb-item">
            <a href="/">Home</a>
          </li>
          <li class="breadcrumb-item active" aria-current="page">장바구니</li>
        </ol>
      </nav>
      <div class="content">
        <div class="d-flex border-bottom">
          <h3 class="mt-3 ms-3">장바구니</h3>
        </div>
        <div class="p-1" v-for="(cart, index) in carts" :key="`cart-${index}`">
          <CartItem :cart="cart" @orderCart="orderCart" @deleteCart="deleteCartConfirm" />
        </div>
        <div class="mt-5 text-center" v-show="carts == null || carts.length == 0">
          <h4 class="text-secondary"><span class="fas fa-shopping-basket fs-3 me-2"></span>장바구니에 담긴 상품이 없습니다.</h4>
        </div>

        <ModalDialog :modalId="'modalDeleteCart'" :title="`장바구니 삭제`" :message="`장바구니 항목을 삭제하시겠습니까?`" :params="tempData" @DialogOk="deleteCart"></ModalDialog>
      </div>
      <FooterContent />
    </div>
  </main>
</template>
<script>
// @ is an alias to /src
import { Modal } from 'bootstrap';
import Navigation from '@/components/common/Navigation.vue';
import FooterContent from '@/components/common/FooterContent.vue';
import ModalDialog from '@/components/common/ModalDialog.vue';
import CartItem from '@/components/cart/CartItem.vue';

export default {
  name: 'CartList',
  components: {
    Navigation,
    FooterContent,
    CartItem,
    ModalDialog,
  },
  data() {
    return {
      user: null,
      carts: [],
      tempData: {},
    };
  },
  created() {
    //console.log('onCreated');
    this.user = this.$func.getUser();
    this.getCartList();
  },
  methods: {
    saveTempData(data) {
      //console.log('saveTempData:', data);
      this.tempData = data;
    },
    getCartList() {
      //console.log('getCartList');
      this.$http
        .get('/cart', {
          params: {
            limit: 1000,
            skip: 0,
          },
        })
        .then((response) => {
          // console.log('response:', response);
          if (response.data.error) {
            this.$toast.error(response.data.message);
            return;
          }

          this.carts = [...response.data];
        })
        .catch((error) => {
          console.log(error);

        });
    },

    orderCart(cart) {
      this.$router.push({
        name: 'order',
        params: { programId: cart.programId, programProductId: cart.programProductId, quantity: cart.quantity },
      });
    },

    deleteCartConfirm(cart) {
      //console.log('deleteCartConfirm:', cart);
      this.tempData = cart;
      var deleteCartModal = new Modal(document.getElementById('modalDeleteCart'), {
        keyboard: false,
      });
      deleteCartModal.show();
    },

    deleteCart(cart) {
      // console.log('deleteCart:', cart);

      this.$http
        .delete(`/cart/${cart.id}`)
        .then((response) => {
          //console.log(response);
          if (response.data.error) {
            // console.log(response.data.message)
            this.$toast.error('서버와의 연결이 불안정 합니다.');
            return;
          }
          this.getCartList();
          this.$toast.success('장바구니 항목이 삭제되었습니다.');
        })
        .catch((error) => {
          this.$toast.error('서버와의 연결이 불안정 합니다.');
          console.log(error);

        });
    },
  },
};
</script>
