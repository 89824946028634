<template>
  <div
    class="modal fade"
    :id="modalId"
    tabindex="-1"
    role="dialog"
    aria-hidden="true"
  >
    <div
      class="modal-dialog modal-dialog-centered"
      role="document"
      style="max-width: 500px"
    >
      <div class="modal-content position-relative">
        <div class="position-absolute top-0 end-0 mt-2 me-2 z-index-1">
          <button
            class="btn-close btn btn-sm btn-circle d-flex flex-center transition-base"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-header">
          <h5 class="modal-title">{{ title }}</h5>
        </div>
        <div class="modal-body">
          <p v-html="message"></p>
        </div>
        <div class="modal-footer">
          <button
            class="btn btn-secondary rounded-pill"
            type="button"
            data-bs-dismiss="modal"
            @click="onClickCancel"
          >
            {{ cancelButton }}
          </button>
          <button
            class="btn btn-primary rounded-pill"
            type="button"
            data-bs-dismiss="modal"
            @click="onClickOk"
          >
            {{ okButton }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name:'ModalDialog',
  props: {
    modalId: {
      type: String,
      // required: ture,
      default: 'ModalDialog',
    },
    title: {
      type: String,
      // required: ture,
      default: '확인',
    },
    message: {
      type: String,
      default: '계속하시겠습니까?',
    },
    params: {
      type: Object,
      default() {
        return {}
      },
    },
    okButton: {
      type: String,
      default: '확인',
    },
    cancelButton: {
      type: String,
      default: '취소',
    },
  },
  created() {
    // console.debug('created params:', this.params)
  },
  watch: {
    params: function(newVal, oldVal) {
      console.info('params changed:', newVal)
      this.params = newVal
    },
  },
  methods: {
    onClickOk() {
      console.debug('onClickOk')
      this.$emit('DialogOk', this.params)
    },
    onClickCancel() {
      console.debug('onClickCancel')
      this.$emit('DialogCancel', this.params)
    },
  },
}
</script>
