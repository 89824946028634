<template>
  <main class="main" id="top">
    <div class="container" data-layout="container">
      <Navigation />
      <div class="content">
        <div class="row g-5 mb-2">
          <div class="col-12">
            <swiper ref="topSwiper" :options="swiperOptions">
              <!-- <swiper-slide v-for="program in programs" :key="program.id">
                <a :href="`/program/${program.ID}`">
                  <img class="rounded-4 img-fluid fit-cover" :src="'/api/resource/SS_PROGRAM/' + program.ID + '.nolto'" alt="" style="width: 100%; height: auto" />
                </a>
              </swiper-slide> -->
              <swiper-slide>
                <img class="img-fluid rounded-3 fit-cover" :src="require('@/img/slide/home_slide_01.png')" alt="" style="width: 100%; height: auto" />
              </swiper-slide>
              <swiper-slide>
                <img class="img-fluid rounded-3 fit-cover" :src="require('@/img/slide/home_slide_02.png')" alt="" style="width: 100%; height: auto" />
              </swiper-slide>
              <swiper-slide>
                <img class="img-fluid rounded-3 fit-cover" :src="require('@/img/slide/home_slide_03.png')" alt="" style="width: 100%; height: auto" />
              </swiper-slide>
              <div class="swiper-pagination" slot="pagination"></div>
              <div class="swiper-button-prev" slot="button-prev" style="color: rgba(255, 255, 255, 0.5)"></div>
              <div class="swiper-button-next" slot="button-next" style="color: rgba(255, 255, 255, 0.5)"></div>
            </swiper>
          </div>
        </div>
        <div class="p-3">
          <div class="row align-items-center">
            <div class="d-none d-lg-block">
              <div class="text-center"><img src="/img/main_pc.svg" class="img-fluid" style="width: 100%; height: auto" /></div>
            </div>
            <div class="d-lg-none">
              <div class="text-center mb-3"><img src="/img/main_mobile.svg" class="img-fluid" style="width: 100%; height: auto" /></div>
            </div>
          </div>
          <!-- / .row -->
        </div>
        <div class="container">
          <div class="row mb-2">
            <div class="col-md-6 col-xl-4 pb-4" v-for="program in programs" :key="program.id">
              <ProgramGridItem :program="program" />
            </div>
            <div class="text-center" v-show="isEmpty">
              <div class="mb-3 mt-6">
                <img class="img-fluid" src="/img/no-data.svg" alt="검색된 체험이 없습니다." style="width: 10rem" />
              </div>
              <div class="mb-4">
                <p class="fs-1 mb-0">검색된 체험이 없습니다.</p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <FooterContent />
    </div>
  </main>
</template>

<script>
// @ is an alias to /src
import Navigation from '@/components/common/Navigation.vue';
import FooterContent from '@/components/common/FooterContent.vue';
import ProgramGridItem from '@/components/program/ProgramGridItem.vue';
import { Swiper, SwiperSlide } from 'vue-awesome-swiper';
import 'swiper/css/swiper.css';
const TAG = 'Home';
const listSize = 12;
export default {
  components: {
    Navigation,
    FooterContent,
    Swiper,
    SwiperSlide,
    ProgramGridItem,
  },
  data() {
    return {
      total: 0,
      programs: [],
      swiperOptions: {
        slidesPerView: 1,
        spaceBetween: 30,
        autoplay: {
          delay: 5000,
          disableOnInteraction: false,
        },
        pagination: {
          el: '.swiper-pagination',
          clickable: true,
        },
        navigation: { nextEl: '.swiper-button-next', prevEl: '.swiper-button-prev' },
      },
    };
  },
  created() {
    this.getProgramList();
  },
  mounted() {
    this.swiper.slideTo(1, 1000, false);
  },
  computed: {
    swiper() {
      return this.$refs.topSwiper.$swiper;
    },
  },
  methods: {
    getProgramList() {
      this.isLoading = true;
      this.isEmpty = false;
      this.$http
          .get('/experience/web', {
            params: {
              limit: listSize,
              skip: 0,
            },
          })
          .then((response) => {
            if (response.status !== 200) {
              this.$toast.error('서버와의 연결이 불안정 합니다.');
              return;
            }
            this.programs = response.data;

            // if (this.programs.length == 0) this.isEmpty = true;
            // console.debug('result - page:', page, 'total:', this.total);
          })
          .catch((error) => {
              console.log(error);
          })
          .finally(() => {
            this.isLoading = false;
          });
    },
  },
};
</script>
