<template>
  <div class="input-group input-group-sm"><span class="input-group-text border-0">옵션값</span>
    <input class="form-control border-0" type="text"
     ref="myOptionValue" 
     v-model="myOptionValue" 
     placeholder="옵션값을 입력하세요."
     @keyup.enter="onChangeOptionValue(true)"
     @blur="onChangeOptionValue(false)"/>
    <button class="btn p-0 ms-2" type="button" 
     v-show="this.optionValue.id > 0"
     title="삭제" 
     data-bs-toggle="modal"
     data-bs-target="#modalDeleteOptionValue"
     @click="onDeleteOptionValue">
      <span class="text-500 fas fa-trash-alt"></span>
    </button>
  </div>
</template>
<script>
export default {
  name: 'OptionValueEdit',
  props: {
    optionValue: {
      type: Object,
      required: true,
      default: {
        id: -1,
        programId: -1,
        optionNo: -1,
        value: null,
        del:false, 
      },
    },
  },
  data() {
    return {
      myOptionValue:'',
    }
  },
  created() {
    //console.log('created - optionValue:', this.optionValue);
    this.myOptionValue = this.optionValue.value;
  },
  mounted() {
    if (this.optionValue.id == -1) {
      this.$refs.myOptionValue.focus();
    }
  },
  //비동기 처리를 위해 watch를 사용한다.
  watch: {
    optionValue: function(newOptionValue) {
      //console.log('watch optionValue:',newOptionValue);
      this.myOptionValue = newOptionValue.value;
    },
  },
  methods: {
    onDeleteOptionValue() {
      //console.log('onDeleteOptionValue:', this.optionValue)
      this.$emit('deleteOptionValue', this.optionValue)
    },

    onChangeOptionValue(afterNew = false) {
      //console.log('onChangeOptionValue:', this.myOptionValue, this.optionValue.value, 'afterNew:',afterNew);
      if ( this.optionValue.id > 0 && (this.myOptionValue == this.optionValue.value)) {
        return;
      }
      if (this.myOptionValue == '' && this.optionValue.id > 0) {
        this.$toast.warning('옵션값을 입력하세요.');
        this.myOptionValue = this.optionValue.value;
        return;
        // this.myOptionValue.del = true;
      }
      const changedValue = this.optionValue;
      changedValue.value = this.myOptionValue;
      this.$emit('changeOptionValue', changedValue, afterNew)
    },
    setFocus() {
      //console.log('setFocus')
      this.$refs.myOptionValue.focus();
    }
  },
}
</script>
