<template>
  <div
    class="modal fade"
    id="modalAttendance"
    tabindex="-1"
    role="dialog"
    aria-hidden="true"
  >
    <div
      class="modal-dialog modal-dialog-centered"
      role="document"
      style="max-width: 500px"
    >
      <div class="modal-content position-relative">
        <div class="position-absolute top-0 end-0 mt-2 me-2 z-index-1">
          <button
            class="btn-close btn btn-sm btn-circle d-flex flex-center transition-base"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-header">
          <h5 class="modal-title">{{modalTitle}}</h5>
        </div>
        <div class="modal-body">
          <p class="mb-1">{{userReservation.isCompletion ? '출석을 취소하시겠습니까?' : '출석을 확인하시겠습니까?'}}</p>
          <p class="mb-1">예약자명 : {{userReservation.booker}} </p>
        </div>
        <div class="modal-footer">
          <button
            class="btn btn-secondary rounded-pill"
            type="button"
            data-bs-dismiss="modal">취소</button>
          <button
            class="btn btn-primary rounded-pill"
            type="button"
            data-bs-dismiss="modal"
            @click="onClickOk">확인</button>
        </div>
        
      </div>
    </div>
  </div>
</template>
<script>
export default {
  name:'AttendanceDialog',
  props: {
    userReservation: {
      type: Object,
      required: true,
      default() {
        return {}
      },
    },
  },
  created() {
    console.info('.created');
  },
  computed: {
    modalTitle() {
      return this.userReservation.isCompletion ? '출석 취소' : '출석 확인'
    },
  },
  //모달은 시작과 동시에 생성되므로 Dialog가 뜰때 반드시 watch를 생성해야함
  watch: {
    productMemo: function(newVal, oldVal) {
      console.info('userReservation changed:', newVal)
      this.userReservation = newVal.memo
    },
  },
  methods: {
    onClickOk() {
      console.info('onClickOk')
      this.$emit('attendance', this.userReservation);
    },
  },
}
</script>
