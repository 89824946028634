<template>
  <footer>
    <div class="container pb-4">
      <div class="row align-items-center">
        <div class="col-12 col-lg-3 mb-3">
          <div class="text-center">
            <a class="mb-2" href="/">
              <img class="me-2" src="/img/logo.svg" alt="nolto logo" style="width: 80px; height: auto" />
            </a>
          </div>
        </div>
        <div class="col-12 col-lg-6 mb-3">
          <div class="text-center text-lg-start">
            <div class="fs--1">(주)문화상상연구소 대표이사 : 김형준</div>
            <div class="fs--1">대전광역시 유성구 북유성대로303, 302호 </div>
            <div class="fs--1">사업자등록번호 : 318-81-03116 | 통신판매업신고 : 제 2020-대전유성-0527 호</div>
            <div class="fs--1">고객센터 : <a href="tel:1833-2562">1833-2562</a> | 제휴 문의 : <a href="mailto:mynolto@naver.com">mynolto@naver.com</a></div>
            <div class="fs--1 mb-2"><a :href="adminPath">관리자 접속</a></div>
            <div class="fw-bold fs--1">Copyright &copy; (주)문화상상연구소 All Rights Reserved.</div>
          </div>
        </div>
        <div class="col-12 col-lg-3">
          <div class="text-center text-lg-end">
            <a href="https://play.google.com/store/apps/details?id=com.mynolto.android" target="blank">
              <img class="img-fluid fit-cover" src="/img/google-store.svg" alt="" style="width: auto; height: 65px" />
            </a>
          </div>
          <div class="text-center text-lg-end mt-2">
            <a href="https://apps.apple.com/kr/app/%EB%82%B4-%EC%86%90%EC%95%88%EC%9D%98-%EC%97%AD%EC%82%AC-%EA%B0%80%EC%9D%B4%EB%93%9C-%EB%86%80%ED%86%A0/id6484502785" target="blank">
              <img class="img-fluid fit-cover" src="/img/apple-store.png" alt="" style="width: 170px; " />
            </a>
          </div>
        </div>
      </div>
      <!-- / .row -->
    </div>
  </footer>
</template>
<script>
export default {
  data() {
    return {
      adminPath: null,
    };
  },
  created() {
    this.adminPath = process.env.VUE_APP_BASE_URL;
  },
};
</script>
