<template>
  <div id="naverIdLogin" style="display: none"></div>
</template>
<script>
import axios from '../../axios';

export default {
  name: 'AuthCallback',
  data() {
    return {
      token: null,
      sns: null,
      access: null, // 네이버 로그인에서 자동 click을 위한 변수
    };
  },
  created() {
    console.log('AuthCallback');
    this.sns = this.$route.params.sns;
    this.access = this.$route.query.access;
  },
  mounted() {
    // 네이버 SNS 로그인
    if(this.sns === 'naver'){
      const { naver } = window;
      const naverLogin = new naver.LoginWithNaverId({
        clientId: process.env.VUE_APP_NAVER_CLIENT_ID,
        callbackUrl: process.env.VUE_APP_SNS_CALLBACK_URL + '/naver',
        isPopup: false, /* 팝업을 통한 연동처리 여부 */
        callbackHandle: true,
        loginButton: { color: 'green', type: 3, height: 58 },
      });

      /* 설정정보를 초기화하고 연동을 준비 */
      naverLogin.init();


      if(this.access !== null){
        const btnNaverLogin = document.getElementById("naverIdLogin_loginButton");
        btnNaverLogin.removeAttribute('href');

        if(!naverLogin.accessToken){
          btnNaverLogin.click();
        }

      }


      naverLogin.getLoginStatus(async (status) => {
        if (status) {
          console.log('naverLogin.getLoginStatus true');
          await this.noltoLogin(naverLogin.user.getEmail(), 'NAVER', naverLogin.user.getId());

        } else {
          console.log('naverLogin.getLoginStatus false');
        }
      });
    }

    // 카카오 SNS 로그인
    if(this.sns === 'kakao'){
      const { Kakao } = window;
      Kakao.init(process.env.VUE_APP_KAKAO_CLIENT_ID);
      Kakao.isInitialized({
        redirectUri: process.env.VUE_APP_SNS_CALLBACK_URL + '/kakao',
      });

      Kakao.Auth.login({
        success: (authObj) => {
          // console.log(authObj); //access토큰 값
          Kakao.Auth.setAccessToken(authObj.access_token); //access 토큰 값 저장

          Kakao.API.request({
            url: "/v2/user/me",
            success:  async (res) => {
              // console.log(res);
              await this.noltoLogin(res.kakao_account.email, 'KAKAO', res.id);

            },
            fail: async (error) => {
              alert('카카오 아이디가 존재하지 않습니다.');
              alert("카카오 로그인 실패" + JSON.stringify(error));
              // 회원가입 페이지로 이동
              // 일반 회원가입
              await this.$router.replace('/');
            },
          });
        },
        fail: (err) => {
          console.log(err);
        },
      });
    }
  },
  methods: {
    async noltoLogin (email, source, sourceId) {
      let result = null;
      try{
        // console.log(email, source, sourceId);
        result = await this.$http.post(`/user/login`, {
          email : email,
          source: source,
          sourceId: `${sourceId}`,
        })

        if (result.data.error) {
          this.$toast.error('서버와의 연결이 불안정 합니다.');
        }

        if(result.data.accessToken){
          this.$func.setCookie('accessToken', result.data.accessToken, 24);

          try{
            let userDataRes1 = await this.$http.get(`/user`); // 일반적인 이용자 정보들
            const userDataRes2 = await this.$http.get(`/my-page`); // 프로필 이미지 주소
            userDataRes1.data.profileImagePath = userDataRes2.data.profileImagePath ?? null;

            localStorage.setItem('userData', JSON.stringify(userDataRes1.data));

            await this.$router.replace(this.$func.getPath() || '/');
          } catch (e) {
            console.log('/user', e);
            alert('서버와의 연결이 불안정 합니다.');
            await this.$router.replace('/');

          }

        }
      }catch (e) {
        if(e.statusCode === 404){
          console.log('회원가입 페이지')
          localStorage.setItem('userData', JSON.stringify({email, source, sourceId}));
          await this.$router.push('/profile/signup');
        }

      }
    }
  },
};
</script>
