<template>
  <main class="main" id="top">
    <div class="container-fluid" data-layout="container-fluid">
      <Navigation />
      <div class="content">
        <Header />
    <div class="row justify-content-center">
      <div class="col-lg-12">
        <!--start content-->
        <div class="card mb-3">
          <div class="card-header">
            <h5 class="mb-0" data-anchor="data-anchor" id="card-styles">프로그램 보고서</h5>
          </div>
          <div class="card-body bg-light border-top">
            <div class="row align-items-center">
              <div class="col d-flex align-items-center">
                <h5 class="card-title">{{ program.title }}</h5>
              </div>
              <div class="col-auto">
                <span :class="`badge rounded-pill d-block p-2 ${$func.getProgramStatusColor(parseInt(program.status))}`">
                  {{$func.getProgramStatusLabel(parseInt(program.status))}}</span>
                <small>{{ `시작일 : ${$func.getShortDate(program.startDate)}` }}</small>
              </div>
            </div>
          </div>
          <div class="card-footer border-top ">
            <a class="btn btn-falcon-default btn-sm ms-2" :href="`/admin/programs`"><span class="far fa-list-alt me-1"></span>프로그램 목록</a>
          </div>
        </div>
        <div class="card mb-3">
          <div class="card-header d-flex ">
            <h5 class="mb-0" data-anchor="data-anchor" id="card-styles">예약 상태별 보고서</h5>
          </div>
          <div class="card-body pt-0">
              <div class="row align-items-center">
                <div class="col d-flex align-items-center">
                  <form class="row gy-2 gx-3 mb-3 align-items-center">
                    <div class="col-auto">
                      <label class="form-label" for="basicOption">기본옵션</label>
                      <select class="form-select form-select-sm" v-model="option1ValueId" id="basicOption" @change="getProgramReport">
                        <option value="-1" selected="selected">전체</option>
                        <option :key="optionValue.id" :value="optionValue.id" v-for="optionValue in option1Values">{{optionValue.value}}</option>
                      </select>
                    </div>
                    <div class="col-auto">
                      <label class="form-label" for="extraOption">추가옵션</label>
                      <select class="form-select form-select-sm" v-model="option2ValueId" id="extraOption" @change="getProgramReport">
                        <option value="-1" selected="selected">전체</option>
                        <option :key="optionValue.id" :value="optionValue.id" v-for="optionValue in option2Values">{{optionValue.value}}</option>
                      </select>
                    </div>
                    <div class="col-auto">
                      <label class="form-label" for="extraOption">상태</label>
                      <select class="form-select form-select-sm" v-model="reservationStatus" id="extraOption" @change="getProgramReport">
                        <option value="1">예약대기</option>
                        <option value="2">예약완료</option>
                        <option value="3">취소요청</option>
                        <option value="4">취소완료</option>
                      </select>
                    </div>
                  </form>
                </div>
                <div class="col-auto">
                  <a class="btn btn-falcon-default btn-sm" 
                        href="#"
                      @click.prevent="downloadReport">
                    <span class="far fa-file-excel me-1"></span>엑셀출력</a>
                </div>
              </div>
              <div class="table-responsive scrollbar">
                <table class="table table-hover table-striped table-bordered fs--1 mb-0" id="table1" v-show="reservationStatus == 1">
                  <thead>
                    <tr>
                      <th class="text-center">기본옵션</th>
                      <th class="text-center">추가옵션</th>
                      <th class="text-center">예약번호</th>
                      <th class="text-center">예약자</th>
                      <th class="text-center">연락처</th>
                      <th class="text-center">가격</th>
                      <th class="text-center">수량</th>
                      <th class="text-center">결제금액</th>
                      <th class="text-center">등록일자</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr class="align-middle" v-for="userReservation in userReservationItems" :key="userReservation.id">
                      <td class="text-center">{{userReservation.option1Value}}</td>
                      <td class="text-center">{{userReservation.option2Value}}</td>
                      <td class="text-center">{{userReservation.reservationNo}}</td>
                      <td class="text-nowrap">{{userReservation.booker}}</td>
                      <td class="text-nowrap">{{userReservation.contact}}</td>
                      <td class="text-end">{{parseInt(userReservation.price).toLocaleString('ko-KR')}}</td>
                      <td class="text-center">{{userReservation.quantity}}</td>
                      <td class="text-end">{{parseInt(userReservation.payout).toLocaleString('ko-KR')}}</td>
                      <td class="text-center">{{$func.getShortDate(userReservation.createTime)}}</td>
                    </tr>
                  </tbody>
                </table>
                <table class="table table-hover table-striped table-bordered fs--1 mb-0" id="table2" v-show="reservationStatus == 2">
                  <thead>
                    <tr>
                      <th class="text-center">기본옵션</th>
                      <th class="text-center">추가옵션</th>
                      <th class="text-nowrap">예약번호</th>
                      <th class="text-nowrap">결제종류</th>
                      <th class="text-center">예약자</th>
                      <th class="text-center">연락처</th>
                      <th class="text-center">결제금액</th>
                      <th class="text-center">정산금액</th>
                      <th class="text-center">출석</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr class="align-middle" v-for="userReservation in userReservationItems" :key="userReservation.id">
                      <td class="text-center">{{userReservation.option1Value}}</td>
                      <td class="text-center">{{userReservation.option2Value}}</td>
                      <td class="text-nowrap">{{userReservation.reservationNo}}</td>
                      <td class="text-nowrap">{{userReservation.paymentMethod == 1 ? '카드결제':'무통장입금'}}</td>
                      <td class="text-nowrap">{{userReservation.booker}}</td>
                      <td class="text-nowrap">{{userReservation.contact}}</td>
                      <td class="text-end">{{parseInt(userReservation.payout).toLocaleString('ko-KR')}}</td>
                      <td class="text-end">{{parseInt(userReservation.settlement).toLocaleString('ko-KR')}}</td>
                      <td class="text-center">{{userReservation.isCompletion ? '출석완료':'미출석'}}</td>
                    </tr>
                  </tbody>
                </table>
                <table class="table table-hover table-striped table-bordered fs--1 mb-0" id="table3" v-show="reservationStatus == 3">
                  <thead>
                    <tr>
                      <th class="text-center">기본옵션</th>
                      <th class="text-center">추가옵션</th>
                      <th class="text-nowrap">예약번호</th>
                      <th class="text-center">예약자</th>
                      <th class="text-center">연락처</th>
                      <th class="text-center">가격</th>
                      <th class="text-center">수량</th>
                      <th class="text-center">결제금액</th>
                      <th class="text-center">요청일자</th>
                      <th class="text-center">환불정보</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr class="align-middle" v-for="userReservation in userReservationItems" :key="userReservation.id">
                      <td class="text-center">{{userReservation.option1Value}}</td>
                      <td class="text-center">{{userReservation.option2Value}}</td>
                      <td class="text-nowrap">{{userReservation.reservationNo}}</td>
                      <td class="text-nowrap">{{userReservation.booker}}</td>
                      <td class="text-nowrap">{{userReservation.contact}}</td>
                      <td class="text-end">{{parseInt(userReservation.price).toLocaleString('ko-KR')}}</td>
                      <td class="text-end">{{userReservation.quantity}}</td>
                      <td class="text-end">{{parseInt(userReservation.payout).toLocaleString('ko-KR')}}</td>
                      <td class="text-center">{{$func.getShortDate(userReservation.createTime)}}</td>
                      <td class="text-nowrap">{{userReservation.cancelDetail}}</td>
                    </tr>
                  </tbody>
                </table>
                <table class="table table-hover table-striped table-bordered fs--1 mb-0" id="table4" v-show="reservationStatus == 4">
                  <thead>
                    <tr>
                      <th class="text-center">기본옵션</th>
                      <th class="text-center">추가옵션</th>
                      <th class="text-nowrap">예약번호</th>
                      <th class="text-nowrap">결제종류</th>
                      <th class="text-center">예약자</th>
                      <th class="text-center">연락처</th>
                      <th class="text-center">결제금액</th>
                      <th class="text-center">취소일자</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr class="align-middle" v-for="userReservation in userReservationItems" :key="userReservation.id">
                      <td class="text-center">{{userReservation.option1Value}}</td>
                      <td class="text-center">{{userReservation.option2Value}}</td>
                      <td class="text-nowrap">{{userReservation.reservationNo}}</td>
                      <td class="text-nowrap">{{userReservation.paymentMethod == 1 ? '카드결제':'무통장입금'}}</td>
                      <td class="text-nowrap">{{userReservation.booker}}</td>
                      <td class="text-nowrap">{{userReservation.contact}}</td>
                      <td class="text-end">{{parseInt(userReservation.payout).toLocaleString('ko-KR')}}</td>
                      <td class="text-center">{{$func.getShortDate(userReservation.createTime)}}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
          </div>
        </div> 
      </div>
    </div>
          <FooterContent />
      </div>
    </div>
  </main>
</template>
<script>
import Header from '@/components/admin/common/Header.vue';
import Navigation from '@/components/admin/common/Navigation.vue';
import FooterContent from '@/components/admin/common/FooterContent.vue';
import * as XLSX from 'xlsx';
import moment from 'moment';
export default {
    name:'programReport',
  components: {
    Header,
    Navigation,
    FooterContent,
    moment
  },
  data() {
    return {
      programId: -1,
      program: {},
      option1Values:[],
      option2Values:[],
      option1ValueId: -1,
      option2ValueId: -1,
      reservationStatus:2,
      userReservationItems:[],
    };
  },
  created() {
    //console.log('created - programId : ' + this.$route.params.id);
    this.programId = this.$route.params.id;
    this.getProgramReservationData();
  },
  methods: {
    getProgramReservationData() {
      //console.log('getProgramReservationData');
      this.$http
        .get('/admin-api/get-program-reservation-data.nolto',{
          params: {
            programId: this.programId,
          }
        })
        .then((response) => {
          //console.log(response);
          if (response.data.error) {
            this.$toast.error(response.data.message);
            return;
          }
          this.program = response.data.program;
          this.option1Values= response.data.option1Values;
          this.option2Values= response.data.option2Values;
         this.getProgramReport();
        })
        .catch((error) => {
          console.log(error);
          
        });
    },

    getProgramReport() {
      //console.log('getProgramReport');

      this.$http
        .get('/admin-api/get-program-report.nolto',{
          params: {
            programId: this.programId,
            option1ValueId: this.option1ValueId,
            option2ValueId: this.option2ValueId,
            status: this.reservationStatus,
          }
        })
        .then((response) => {
          //console.log(response);
          if (response.data.error) {
            this.$toast.error(response.data.message);
            return;
          }
          this.userReservationItems = response.data.userReservationItems;
        })
        .catch((error) => {
          console.log(error);
          
        });
    },
    downloadReport() {
      //console.log('downloadReport');
      const sheetName = this.getStatusLabel(this.reservationStatus);
      let wb = XLSX.utils.table_to_book(document.getElementById('table' + this.reservationStatus), {sheet:sheetName,raw:true});
      const fileName = this.program.title + '(' + this.getStatusLabel(this.reservationStatus) + ')-'+moment().format('yyyy-MM-DD')+'.xlsx';
      XLSX.writeFile(wb, fileName);
    },
    getStatusLabel(status) {
      //console.log('getStatusLabel',status);
      switch(parseInt(status)) {
        case 1: 
          return '예약대기';
        case 2: 
          return '예약완료'
        case 3:
          return '취소요청'
        case 4:
          return '취소완료'
        default:
          return '해당없음';
      }
    }
  },
};
</script>
