<template>
  <div
    class="modal fade"
    id="modalProductMemo"
    tabindex="-1"
    role="dialog"
    aria-hidden="true"
  >
    <div
      class="modal-dialog modal-dialog-centered"
      role="document"
      style="max-width: 640px"
    >
      <div class="modal-content position-relative">
        <div class="position-absolute top-0 end-0 mt-2 me-2 z-index-1">
          <button
            class="btn-close btn btn-sm btn-circle d-flex flex-center transition-base"
            data-bs-dismiss="modal"
            aria-label="Close"
          ></button>
        </div>
        <div class="modal-header">
          <h5 class="modal-title">{{ modalTitle }}</h5>
        </div>
        <div class="modal-body">
            <label class="form-label" for="inputMemo">메모</label>
            <textarea class="form-control" ref="inputMemo" id="inputMemo" v-model="memoContents" rows="3"></textarea>
        </div>
        <div class="modal-footer">
          <button
            class="btn btn-secondary rounded-pill"
            type="button"
            data-bs-dismiss="modal"
          >
            취소
          </button>
          <button
            class="btn btn-primary rounded-pill"
            type="button"
            data-bs-dismiss="modal"
            :disabled="!isWritable"
            @click="onClickOk"
          >
            저장
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Editor from '@tinymce/tinymce-vue'
export default {
  name: 'ProductProductMemoDialog',
  components: {
    editor: Editor,
  },
  props: {
    productMemo: {
      type: Object,
      required: true,
      default() {
        return {id:-1, programProductId:-1,memo:'',userUid:''}
      },
    },
  },
  created() {
    console.info('.created');
  },
  data() {
    return {
      memoContents:''
    }
  },
  computed: {
    modalTitle() {
      return this.productMemo.id > 0 ? '메모 수정' : '새로운 메모'
    },
    isWritable() {
      return this.memoContents && this.memoContents.length > 0;
    },
  },
  //모달은 시작과 동시에 생성되므로 Dialog가 뜰때 반드시 watch를 생성해야함
  watch: {
    productMemo: function(newVal, oldVal) {
      console.info('productMemo changed:', newVal)
      this.memoContents = newVal.memo
    },
  },
  methods: {
    onClickOk() {
      console.info('onClickOk')
      this.productMemo.memo = this.memoContents;
      this.$emit('saveMemo', this.productMemo);
    },
  },
}
</script>
