<template>
  <div class="main" id="top">
    <div class="container" data-layout="container">
      <div class="row flex-center min-vh-100 py-6">
        <div class="col-sm-10 col-md-8 col-lg-6 col-xl-5 col-xxl-4">
          <a class="d-flex flex-center mb-4" href="/"
            ><img class="me-2" src="/img/logo.svg" alt="" width="180"
          /></a>
          <div class="text-center">
            <div class="mb-3 mt-5">
              <img class="img-fluid" src="/img/404.svg" alt="Image Description" style="width: 30rem;" />
            </div>

            <div class="mb-4">
              <p class="fs-4 mb-0">페이지를 찾을 수 없습니다.</p>
            </div>
            <a class="btn btn-primary" href="/">첫 페이지로 가기</a>
          </div>
        </div>
      </div>
      <FooterContent />
    </div>
  </div>
</template>
<script>
// @ is an alias to /src
import FooterContent from '@/components/common/FooterContent.vue';
export default {
  components: {
    FooterContent,
  },
};
</script>
