<template>
  <div class="main" id="top">
    <div class="container" data-layout="container">
      <div class="row flex-center min-vh-100 py-6">
        <div class="col-sm-10 col-md-7 col-lg-5 col-xl-4 col-xxl-4">
          <a class="d-flex flex-center mb-4" href="/">
            <img class="me-2" src="/img/logo.svg" alt="" width="80"/>
          </a>
          <div class="card">
            <div class="card-body p-4 p-sm-4">
              <div class="row flex-between-center mb-3">
                <div class="text-center mb-3">
                  <h3>로그인</h3>
                </div>
                <div class="text-center mb-3">
                  <div id="naverIdLogin" style="display: none"></div>
                  <img src="/img/naver_login_btn.png" style="cursor: pointer; width:240px" alt="네이버로그인" @click="snsLogin('naver')">
                </div>
                <div class="text-center">
                  <img src="/img/kakao_login_btn.png" style="cursor: pointer; width:240px" alt="카카오로그인" @click="snsLogin('kakao')">
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <FooterContent/>
    </div>
  </div>
</template>
<script>
// @ is an alias to /src
import FooterContent from '@/components/common/FooterContent.vue';

export default {
  components: {
    FooterContent,
  },
  created() {

  },
  mounted() {
  },
  computed: {

  },
  methods: {

    snsLogin(sns) {
      this.$func.setPath('/');
      window.location.replace(`${process.env.VUE_APP_SNS_CALLBACK_URL}/${sns}`);
    },
  }
};
</script>
