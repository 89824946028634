export default {
  maxImageSize: 10485760,
  loginEndpoint: '/auth/login',
  registerEndpoint: '/auth/register',
  refreshEndpoint: '/auth/refresh-token',
  logoutEndpoint: '/auth/logout',
  activateEndpoint: '/auth/activate',
  forgotPasswordEndpoint: '/auth/forgot-password',
  resetPasswordEndpoint: '/auth/reset-password',
  verifyTokenEndpoint: '/auth/verify-token',

  // This will be prefixed in authorization header with token
  // e.g. Authorization: Bearer <token>
  tokenType: 'Bearer',

  // Value of this property will be used as key to store JWT token in storage
  storageTokenKeyName: 'accessToken',
  storageRefreshTokenKeyName: 'refreshToken',
  //놀토 운영
  bootPayJavascriptKey: '60864c995b2948001d07bd38',
  bootPayPrivateKey: 'r1OcBbZUFKj2PBMMUSyqmBNW0zD4VL5BzuhNk/NHgGg=',
  //놀토 개발
  // bootPayJavascriptKey: '626f9d112701800021f69bde',
  // bootPayPrivateKey: 'NriBFS1fjtWeIWyCHdqvxHZ4Qh+XBnaEzGDxVNDlO6k=',
}
