<template></template>
<script>
export default {
  name: 'AddCart',
  data() {
    return {
      user: null,
      cartData: null,
    };
  },
  created() {
    //console.log('onCreated - params : ' + this.$route.params);
    this.user = this.$func.getUser();
    this.cartData = this.$route.params;
    if (this.cartData.programId && this.cartData.programProductId && this.cartData.quantity > 0) {
      this.saveCart();
    } else {
      this.$router.push('/');
    }
  },
  methods: {
    saveCart() {
      //console.log('saveCart');
      if (!this.$func.isLoggedIn()) {
        this.$toast.error('로그인에 문제가 있습니다. 로그인을 다시 시도해주세요.');
        this.$router.push('/login');
        return;
      }
      this.cartData.userUid = this.user.uid;
      this.$http
        .post('/user-api/add-cart.nolto', this.cartData)
        .then((response) => {
          //console.log(response);
          if (response.data.error) {
            this.$toast.error(response.data.message);
            return;
          }
          this.$toast.success(response.data.message);
          this.$router.push('/cart');
        })
        .catch((error) => {
          console.log(error);
          
        });
    },
  },
};
</script>
