<template>
  <nav class="navbar navbar-light navbar-glass navbar-top navbar-expand-lg">
    <div class="container">
      <a class="navbar-brand me-1 me-sm-3" href="/">
        <div class="d-flex align-items-center"><img class="me-2" src="/img/nolto-logo.svg" alt="" style="width: 275px" /></div>
      </a>
      <button class="navbar-toggler collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#navbarStandard" aria-controls="navbarStandard" aria-expanded="false" aria-label="Toggle navigation">
        <span class="navbar-toggler-icon"></span>
      </button>
      <div class="collapse navbar-collapse scrollbar" id="navbarStandard">
        <ul class="navbar-nav" data-top-nav-dropdowns="data-top-nav-dropdowns">
          <li class="nav-item">
            <a class="nav-link" :class="$route.path.indexOf('/programs') >= 0 || $route.path.indexOf('/program-detail') >= 0 ? ' active' : ''" href="/programs">오감체험</a>
          </li>
          <li class="nav-item">
            <a class="nav-link" target="_blank" href="https://docs.google.com/forms/d/e/1FAIpQLSde_uT-vG7M2gPICViX0p_e9LtTb_Cs6199MTWzS61mfA6EKg/viewform?usp=sf_link">입점 신청</a>
          </li>
          <li class="nav-item" v-show="$func.isLoggedIn()">
            <a class="nav-link" :class="$route.path.indexOf('/bookmarks') >= 0 ? ' active' : ''" href="/bookmarks">찜목록</a>
          </li>
<!--          <li class="nav-item" v-show="$func.isLoggedIn()">-->
<!--            <a class="nav-link" href="/report/make.nolto" target="blank">보고서작성</a>-->
<!--          </li>-->
          <li class="nav-item" v-show="$func.isLoggedIn()">
            <a class="nav-link" :class="$route.path.indexOf('/cart') >= 0 ? ' active' : ''" href="/cart">장바구니</a>
          </li>
          <li class="nav-item" v-show="$func.isLoggedIn()">
            <a class="nav-link" :class="$route.path.indexOf('/reservations') >= 0 || $route.path.indexOf('/reservations') >= 0 ? ' active' : ''" href="/reservations">예약내역</a>
          </li>
        </ul>
        <ul class="navbar-nav ms-auto">
          <li class="nav-item dropdown" v-if="$func.isLoggedIn()">
            <a class="nav-link pe-0" id="navbarDropdownUser" href="#" role="button" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
              <div class="avatar avatar-2xl">
                <img v-if="profileImagePath == null" class="rounded-circle" ref="profileImage" src="/img/default-profile.png" alt="" style="object-fit: contain" />
                <img v-else class="rounded-circle" ref="profileImage" :src="`${profileImagePath}`" alt="" style="object-fit: cover" />

              </div>
            </a>
            <div class="dropdown-menu dropdown-menu-end py-0" aria-labelledby="navbarDropdownUser">
              <div class="bg-white dark__bg-1000 py-2 rounded-3">
<!--                <a class="dropdown-item" href="/admin/programs" target="blank" v-if="this.$func.isAdmin() || this.$func.isManager()">-->
<!--                  <span class="fas fa-user me-2"></span>-->
<!--                  <span>관리자 접속</span>-->
<!--                </a>-->
                <div class="dropdown-divider" v-if="this.$func.isAdmin() || this.$func.isManager()"></div>
                <a class="dropdown-item" href="/profile/edit">
                  <span class="fas fa-user me-2"></span>
                  <span>사용자 정보 수정</span>
                </a>
                <div class="dropdown-divider"></div>
                <a class="dropdown-item" @click="logout()"> <span class="fas fa-power-off me-2"></span><span>로그아웃</span> </a>
              </div>
            </div>
          </li>
          <li class="nav-item" v-else>
            <a class="nav-link dropdown-toggle" id="navbarDropdownLogin" href="#" role="button" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">로그인</a>
            <div class="dropdown-menu dropdown-menu-end dropdown-menu-card" aria-labelledby="navbarDropdownLogin">
              <div class="card shadow-none navbar-card-login">
                <div class="card-body fs--1 pe-3 ps-3 pb-3 fw-normal">
                  <div class="mb-3 text-center">
                    <h4>로그인</h4>
                  </div>
                  <div class="text-center mb-3">
                    <a :href="naverLoginUrl"><img src="/img/naver_login_btn.png" style="width: 260px" alt="네이버로그인" @click="snsLogin('naver')" /></a>
                  </div>
                  <div class="text-center mb-3">
                    <a :href="kakaoLoginUrl"><img src="/img/kakao_login_btn.png" style="width: 260px" alt="카카오로그인" @click="snsLogin('kakao')" /></a>
                  </div>
                </div>
              </div>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </nav>
</template>
<script>
const TAG = 'Navigation';
export default {
  data() {
    return {
      user: {},
      rand: 1,
      profileImagePath: null,
      adminPath: null
    };
  },
  created() {
    this.user = this.$func.getUser();
    // console.log(this.user);
    if(this.user != null && this.user.profileImagePath != null){
      this.profileImagePath =  process.env.VUE_APP_BASE_URL + this.user.profileImagePath
    }



  },
  computed: {
    naverLoginUrl() {
      this.$func.setPath(this.$route.path);
      return `${process.env.VUE_APP_SNS_CALLBACK_URL}/naver?access=true`;
    },
    kakaoLoginUrl() {
      this.$func.setPath(this.$route.path);
      return `${process.env.VUE_APP_SNS_CALLBACK_URL}/kakao?access=true`;
    },
  },
  methods: {

    refreshProfileImage() {
      this.rand++;
    },
    logout() {
      if (process.env.NODE_ENV == 'remote') {
        this.$router.push('/logout');
      } else {
        console.log('로그아웃');
        this.$router.push('/logout');
      }
    },
  },
};
</script>
