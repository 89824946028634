<template>
  <div class="d-flex border-bottom p-3">
    <div class="me-3 align-self-center">
      <button class="btn btn-falcon-default btn-sm" type="button" @click="onDeleteCart"><span class="fas fa-trash-alt"></span></button>
    </div>
    <div class="avatar avatar-4xl">
      <img class="rounded-soft" :src="imageUrl" alt="" />
    </div>
    <div class="flex-1 ms-3">
      <div class="row align-items-between">
        <div class="col-lg-4">
          <div class="d-flex flex-column">
            <h6 class="fs-0 mb-0"><a :href="`/program/${cart.programId}`">{{ cart.title }}</a></h6>
            <p class="fs--1">{{ cart.area }}</p>
          </div>
        </div>
        <div class="col-lg-4 ">
          <div class="d-flex flex-column">
            <div class="mb-1 fs--1">{{ cart.option1Value }}</div>
            <div class="fs--1">{{ cart.option2Value }}</div>
          </div>
        </div>
        <div class="col-lg-2">
          <div class="d-flex flex-column">
            <div class="mb-1 align-self-end">{{ productPrice }}원</div>
            <div class="mb-0 align-self-end">× {{ cart.quantity }}</div>
          </div>
        </div>
        <div class="col-lg-2 ms-auto">
          <div class="d-flex flex-column">
            <div class="mb-1 align-self-end">
              <h5 class="mb-2">{{ totalPrice }}원</h5>
            </div>
            <div class="mb-0 align-self-end">
              <button style="min-width: 75px" class="btn btn-falcon-primary rounded-pill btn-sm" type="button" @click="onOrderCart()">주문하기</button>
<!--              <h5 v-show="!showOrderButton" class="mb-2 text-secondary">{{ errorMessage }}</h5>-->
            </div>
            </div>
          </div>
        </div>
      </div>
    </div>
</template>
<script>
export default {
  name: 'Cart',
  components: {},
  data() {
    return {};
  },
  props: {
    cart: {
      type: Object,
      required: true,
      default: {
        id: -1,
        programId: -1,
        programProductId: -1,
        quantity: 0,
        price: 0,
        extraPrice: 0,
        title: '오감체험 제목',
        area: '체험장소',
        option1Value: '',
        option2Value: '',
        imagePath: '',
        isUse: 0,
        isSoldOut: 1,
        // IS_END: 1,
        // PRODUCT_QUANTITY: 0,
        reservedQuantity: 0,
      },
    },
  },
  created() {
    console.debug('created - cart:', this.cart);
  },
  computed: {
    imageUrl() {
      return process.env.VUE_APP_BASE_URL + this.cart.imagePath;
    },
    // showOrderButton() {
    //   const availableQuantity = this.cart.PRODUCT_QUANTITY - this.cart.RESERVED_QUANTITY;
    //   return this.cart.STATUS == 1 && this.cart.IS_USE == 1 && this.cart.IS_SOLD_OUT != 1 && this.cart.IS_END == 0 && availableQuantity >= this.cart.QUANTITY;
    // },
    errorMessage() {
      // if (this.cart.STATUS != 1) return '마감'
      // if (this.cart.IS_USE == 0 || this.cart.IS_END == 1) return '종료'
      // if (this.cart.IS_SOLD_OUT == 1) return '매진'
      // const availableQuantity = this.cart.PRODUCT_QUANTITY - this.cart.RESERVED_QUANTITY;
      // if (availableQuantity < this.cart.quantity) return '수량부족'
    },
    productPrice() {
      // console.log(this.cart);
      const price = this.cart.price / this.cart.quantity;

      return parseFloat(price).toLocaleString('ko-KR');
    },
    totalPrice() {

      return parseFloat(this.cart.price).toLocaleString('ko-KR');
    },
  },
  methods: {
    onDeleteCart() {
      //console.log('onDeleteCart - cart:', this.cart);
      this.$emit('deleteCart', this.cart);
    },
    onOrderCart() {
      //console.log('onOrderCart - cart:', this.cart);
      this.$emit('orderCart', this.cart);
    },
  },
};
</script>
