import Vue from 'vue';
import App from './App.vue';
import router from './router';
import axios from './axios';
import { commonFunc } from './common';
import Meta from 'vue-meta';
import vueMoment from 'vue-moment';
import Toast from 'vue-toastification';
import config from './config';
import VueClipboard from 'vue-clipboard2';
import 'vue-toastification/dist/index.css';
import InfiniteLoading from 'vue-infinite-loading';

import "bootstrap";
import "bootstrap/dist/css/bootstrap.min.css";

//VueMeta
Vue.use(Meta);
Vue.use(VueClipboard);
Vue.use(InfiniteLoading, {
  slots: {
    noMore: '',
  },
});
//toast default/success/info/warning/error
Vue.use(Toast, {
  // transition: 'Vue-Toastification__bounce',
  transition: 'Vue-Toastification__fade',
  maxToasts: 1,
  newestOnTop: true,
  position: 'top-center',
  timeout: 1500,
  closeOnClick: true,
  pauseOnFocusLoss: true,
  pauseOnHover: true,
  draggable: true,
  draggablePercent: 0.6,
  showCloseButtonOnHover: false,
  hideProgressBar: true,
  closeButton: 'button',
  icon: true,
  rtl: false,
});

// import theme.scss styles
require('@/scss/global.css');
require('@/scss/theme.scss');
require('@/scss/user.scss');
Vue.config.productionTip = false;
Vue.prototype.$http = axios;
Vue.prototype.$func = commonFunc;
Vue.prototype.$config = config;

new Vue({
  render: (h) => h(App),
  router,
  vueMoment,
}).$mount('#app');
